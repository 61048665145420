.account-class {
  width: 100% !important;
  height: 100% !important;
  background-color: #ffffff;

  background-image: url('../../../images/AccountBG.png');
  background-repeat: no-repeat;
  background-position: top left;
}

#contributor-picture {
  position: relative;
  top: 40px;
  margin-left: 60px;
}

#edit-icon {
  margin-right: 5px;
  margin-top: -3px;
}

.edit-item {
  margin-top: 15px;
  margin-right: 15px;
  background-color: transparent;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #67db88;
  cursor: pointer !important;
  float: right;
}
.edit-item:hover {
  text-decoration: none;
  color: #67db88;
}

.contributor-Name {
  position: relative;
  top: 50px;
  margin-left: 25px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #263238;
}

.contributor-Contact {
  font-family: Montserrat;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #90ad98;

  margin-top: 90px;
  margin-left: 25px;
  margin-bottom: 20px;

  text-transform: uppercase;
}

.contributor-location-section {
  font-family: Montserrat;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #263238;

  margin-left: 25px;
}

.contributor-phone-section {
  font-family: Montserrat;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #263238;

  margin-left: 25px;
}

.contributor-email-section {
  font-family: Montserrat;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #263238;

  margin-left: 25px;
}

.contributor-account-payment-button {
  font-family: Montserrat;

  width: 282px;
  height: 32px;

  border: 1px solid #7cb1ff;
  box-sizing: border-box;
  border-radius: 2px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.06em;

  color: #7cb1ff;
  background-color: transparent;

  margin-left: 25px;
  margin-top: 20px;
}

#call-icon {
  margin-right: 10px;
}

#message-icon {
  margin-right: 10px;
}

#location-icon {
  margin-right: 10px;
}

.contributor-account-payment-cards {
  justify-content: center;
  margin-right: auto;
  width: 282px;
  margin-left: 25px;
  padding-bottom: 25px !important;
}
