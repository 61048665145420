.donationsHistoryTab {
    margin: 10px 0;
    background: #FFFFFF;
    display: inline-flex;
    padding: 16px 24px;
    box-shadow: 0px 4px 12px rgba(124, 177, 255, 0.25);
    border-radius: 4px;
}

.title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #263238;
}

.showMoreBtn {
    background-color: white;
    color: #67db88;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
}
