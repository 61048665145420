.recurring-container {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(124, 177, 255, 0.25);
    border-radius: 4px;
    height: fit-content;
    display: flex;
    height: -moz-fit-content;
}

.recurring-top-bg {
    background-image: url("../../../images/ic_recurring_top_bg.png");
    width: 196px;
    min-width: 196px;
    height: 242px;
    background-repeat: no-repeat;
    position: sticky;
}

.recurring-container .RSPBprogressBar {
    top: unset;
    margin-top: 60px;
}

.recurring-inner-container {
    margin-top: 53px;
    margin-left: auto;
    margin-right: auto;
}

.recurring-main-inner-container {
    margin-top: 45px;
    width: 100%;
    margin-left: -196px;
}