#InfoSection {
    background: #EEFFF3;
    margin-top: -435px;
    padding-top: 435px;
}

.get-app-btn {
    height: 85px;
    width: 287px;
    border: unset;
}

.google-play-btn {
    background: url("../../images/google_play.png");
}

.app-store-btn {
    background: url("../../images/app_store.png");
    margin-top: 47px;
}

#InfoSection .title-text {
    color: #263238;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 37px;
    letter-spacing: 0em;
}

#InfoSection .sub-title-text {
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
}

#InfoSection .sub-title-text-div {
    margin-top: 20px;
}

.get-app-text {
    margin-top: 54px;
}

.hand-text {
    margin-top: 43.8px;
}

.hand-info-section-img {
    height: 236.4px;
    width: 246.8px;
}

.info-secton-btn {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: none;
    padding: 0px;
}

#InfoSection .register-btn {
    height: 35px;
    width: 210px;
    border-radius: 4px;
    background: #67DB88;
    margin-top: 42px;
}

#InfoSection .get-started-btn {
    height: 35px;
    width: 210px;
    border-radius: 4px;
    background: #FFFFFF;
    border: 1px solid #67DB88;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 25px;
    color: #67DB88;
}

.secure-img {
    Width: 381px;
    Height: 302px;
}

#InfoSection .secure-text {
    font-family: Montserrat;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

#InfoSection .transparent-section {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(124, 177, 255, 0.25);
    border-radius: 4px;
}

#InfoSection .transparent-section-inner {
    margin-top: 46px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 42px;
}

#InfoSection .transparent-section-title {
    color: #67DB88;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 37px;
    letter-spacing: 0em;
    text-align: left;
}

#InfoSection .transparent-section-sub-title {
    color: #263238;
    font-family: Montserrat;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 38px;
}

#InfoSection .transparent-section-sub-2-title {
    color: #7CB1FF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 43px;
}

#InfoSection .footer-bg-1 {
    background: url("../../images/vector_blue_info_section.png");
    height: 728px;
    position: absolute;
    background-color: transparent;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0px;
    background-position: center center;
}

#InfoSection .footer-bg-2 {
    background-color: transparent;
    background: url("../../images/vector_green_info_section.png");
    height: 509px;
    width: 100%;
    background-repeat: no-repeat;
    z-index: 9;
    position: absolute;
    background-size: cover;
    bottom: 0px;
}

#InfoSection .contact-us-info-section {
    font-family: Montserrat;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
    margin-top: 60px;
}

#landing-footer {
    position: relative;
    background-color: transparent;
    height: 728px;
    margin-top: -180px;
}

.contact-us-info-section-email {
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
    margin-top: 6px;
}

.contact-us-info-section-social-media {
    margin-top: 12px;
    display: flex;
}

.contact-us-info-section-social-media-item {
    height: 24px;
    width: 24px;
}

#InfoSection .visit-sadaqa-container {
    text-align: center;
    position: relative;
}

#InfoSection .are-you-ready {
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}

#InfoSection .contact-us-arrow {
    width: 52.23px;
    height: 66.38px;
    margin-top: -35px;
    margin-right: 35px;
}

#InfoSection .contact-us-visit-sadaqa-container {
    margin-top: 55px;
    margin-right: -35px;
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    float: right;
    left: -30px;
}

#InfoSection .contact-us-visit-sadaqa-btn {
    width: 170px;
    height: 35px;
    color: #7CB1FF;
    background-color: #FFFFFF;
    border-radius: 4px;
    margin-top: 10px;
}

#InfoSection .back-to-top {
    margin-top: 20px;
    display: flex;
}

#InfoSection .back-to-top-container {
    width: 83.87px;
    height: 87.34px;
    background: url("../../images/smallgreenbox.png");
    background-size: cover;
    text-align: center;
    float: right;
    position: relative;
    cursor: pointer;
    margin-left: 5px;
}

#InfoSection .back-to-top-text {
    color: #FFFFFF;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    padding-top: 30px;
    margin-left: auto;
    text-align: right;
}

#InfoSection .back-to-top-container img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.contact-us-container-info-section {
    margin-top: 20px;
    text-align: left;
}

.landing-footer-items {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    bottom: 0px;
    padding-top: 320px;
}

.footer-app-version {
    text-align: center!important;
    margin-top: 40px;
}

.app-version_p {
    padding: 15px 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    color: #FFFFFF;
}

@media (max-width: 720px) {
    .get-app-btn {
        Width: 233px;
        Height: 69.01px;
        background-size: cover;
    }
    #InfoSection .contact-us-info-section {
        margin-top: 32px;
    }
    .landing-footer-items {
        padding-top: 280px;
        padding-left: unset;
    }
    .landing-footer-items-container {
        padding-left: 30px;
        max-width: 100%;
    }
    .contact-us-container-info-section {
        margin-top: -35px!important;
        padding-left: 50px;
    }
    .visit-sadaqa-container {
        height: 165px;
    }
    .contact-us-info-section {
        padding-left: 10px;
    }
    .contact-us-info-section-email {
        padding-left: 10px;
    }
    .contact-us-info-section-social-media {
        padding-left: 10px;
    }
    #InfoSection .secure-text {
        margin: unset;
        position: unset;
        top: unset;
        transform: unset;
    }
    .back-to-top {
        margin-top: -105px !important;
        position: absolute;
    }
    #InfoSection .back-to-top-container {
        margin-right: 5px;
    }
    .transparent-section-inner {
        margin-top: 30px !important;
        margin-left: 25px !important;
        margin-right: 25px !important;
        margin-bottom: 35px !important;
    }
    .transparent-section-title {
        font-size: 24px !important;
    }
    .transparent-section-sub-title {
        margin-top: 30px !important;
        font-size: 20px !important;
    }
    .transparent-section-sub-2-title {
        margin-top: 30px !important;
        font-size: 18px !important;
    }
    .secure-img-container {
        margin-bottom: unset !important;
        text-align: center;
    }
    .secure-img {
        width: 233px;
        height: 184.38px;
    }
    .secure-text-container {
        margin-top: 44px !important;
    }
    .secure-text {
        font-size: 20px !important;
    }
    #InfoSection .back-to-top-text {
        font-size: 14px !important;
        line-height: 19.6px;
    }
    .footer-app-version {
        margin-top: 0px;
    }
}
