.no-project-main-container {
  width: 100%;
}

.no-project-inner-container {
  width: 100%;
}

.org-projects-section-container {
  display: inline-block;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: -30px;
}

.org-projects-section-item {
  display: inline-block;
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 15px;
}

@media (max-width: 720px) {
  .org-projects-section-item {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .org-projects-section-container {
    margin-top: 3px;
  }
}
