.rec_info_card {
    width: 410px;
    height: 430px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
    border-radius: 4px;
}

.rec_info_card_body {
    margin: 15px 35px;
}

.supporting_span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #7CB1FF;
}

.recurring_aligned {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.recurring_donation-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #92E3A9;
}

.recurring_donation-name {
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
}

.recurring-hr {
    border: 1px solid #92E3A9;
    margin-top: 25px;
}

.payment_info_lbl {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #7CB1FF;
    margin-top: 25px;
}

.recurring_span {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 7px;
}

.recurring_type_lbl, .recurring_amount_lbl {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #263238;
}

.recurring_total_lbl {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #263238;
}

.recurring_type_txt {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #263238;
}

.recurring_amount_txt {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #263238;
}

.recurring_total_txt {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #67DB88;
}

.recurring_details-div {
    margin-top: 20px;
}

.end_recurring-div {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
}

.end_recurring-btn {
    width: 285px;
    height: 40px;
    background: #FF7E7E;
    border-radius: 2px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    border: thin;
}

.ended_recurring-div {
    width: 285px;
    height: 40px;
    background: #FF7E7E;
    border-radius: 2px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    border: thin;
    opacity: 0.5;
    text-align: center;
}

@media (max-width: 720px) {
    .rec_info_card {
        width: 100%;
    }
    .rec_info_card_body {
        margin: 15px 0px;
    }
}