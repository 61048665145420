.trending-projects-section-container {
    display: inline-block;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 20px;
}

.trending-projects-section-item {
    display: inline-block;
    margin-left: 0px;
    margin-top: 30px;
}

@media (max-width: 720px) {
    .trending-projects-section-item {
        margin-top: 20px;
    }
    .trending-projects-section-container {
        margin-top: 3px;
    }
}