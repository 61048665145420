.register_organization_step_two {
  position: relative;
  top: 115px;
}

.about_organization {
  width: 284px;
  background: #fdfdfd;
  border: 1px solid #c5e8cf;
  box-sizing: border-box;
  border-radius: 4px;
}

.reg_org_lbl {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #263238;
}

#agree_checkbox {
  position: relative;
  width: 16px;
  height: 16px;
  right: 6px;
  top: 3px;
  background: #ffffff;
  border: 1px solid #7cb1ff;
  box-sizing: border-box;
  border-radius: 3px;
  margin: 0px 3px;
}

#agree_checkbox label {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.end_user {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #7cb1ff;
  position: relative;
  /* top: 5px;
  left: 5px; */
  text-decoration: underline;
}

#licence-div {
  /* width: 284px; */
  position: relative;
  /* top: 210px; */
}

@media (max-width: 720px) {
  .upload_file_form {
    position: relative;
    left: 3px;
  }
  #licence-div {
    left: 3px;
    top: 0px;
  }
}

.custom-dropdown-menu {
  height: 200px;
  overflow-y: auto;
}

.register_organization_button {
  position: relative;
  width: 100px;
  height: 35px;
  background: #67db88;
  color: #ffffff;
  border-radius: 4px;
  text-transform: unset;
  padding: 0px;
  float: right;
  left: 83px;
  top: 112px;
}

.register_organization_button:hover {
  background: #67db88;
}

@media (max-width: 720px) {
  .register_organization_button {
    left: 17px;
    top: 0px;
  }
}

.org_signup_error_step_2 {
  color: #ff0000;
  font-size: 10px;
  margin-top: 5px;
}

.file_upload_error {
  color: #ff0000;
  font-size: 10px;
  position: relative;
  bottom: 18px;
}

.css-tlfecz-indicatorContainer,
.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-tj5bde-Svg {
  color: #7cb1ff;
}

.css-oxawsw-control {
  border: 1px solid #c5e8cf;
}

.css-1rhbuit-multiValue {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 5px;
  width: 90px;
  height: 15px;
  background-color: #7cb1ff !important;
  border-radius: 2px;
}

.css-12jo7m5 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 9px !important;
  line-height: 11px;
  color: #ffffff !important;
}

.css-tj5bde-Svg {
  position: relative;
  left: 5px;
  color: #3284ff;
}

.css-xb97g8,
.css-tj5bde-Svg:hover {
  background-color: #7cb1ff !important;
}

.css-4je539-control {
  width: 284px;
  background: #fdfdfd;
  border: 1px solid #c5e8cf;
  box-sizing: border-box;
  border-radius: 4px;
}
