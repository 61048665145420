.admin_specific_org_info_card, .contrib_specific_org_info_card {
    width: 100%!important;
    /* height: 100%!important; */
}

.org_rate {
    position: relative;
    bottom: 15px;
}

#sp_donate-btn {
    width: 290px;
    height: 40px;
    background: #67DB88;
    border-radius: 2px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FDFDFD;
    margin-bottom: 10px;
}

#sp_share-btn {
    width: 80px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #7CB1FF;
    box-sizing: border-box;
    border-radius: 2px;
}

#sp_share-btn > p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #7CB1FF;
    letter-spacing: 0em;
    text-transform: lowercase;
    margin-top: -3px;
}

#sp_share-btn > p::first-letter {
    text-transform: uppercase;
}

#share-icon {
    float: left;
}

.specific_org_donate-div {
    width: 100%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

#sp_contact-btn {
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #7CB1FF;
    box-sizing: border-box;
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.06em;
    color: #7CB1FF;
    text-transform: unset;
    width: 140px;
}

.con_share-grp {
    width: 290px;
}

.reject-org-btn {
    width: 100px;
    height: 32px;
    left: 363px;
    top: 703px;
    background-color: #FF7E7E;
    border-radius: 2px;
    font-weight: 500;
    line-height: 22px;
    color: #FFFFFF;
    border: unset;
}

.accept-org-btn {
    background-color: #67DB88;
}
.specific_org_info_banner {
    width: 200px;
    height: 200px;
}
@media (max-width: 720px) {
    .admin_specific_org_info_card {
        height: 350px!important;
    }
    .contrib_specific_org_info_card {
        height: 335px!important
    }
    .specific_org_green_vector {
        width: 81px;
        height: 91px;
    }
    .specific_org_info_banner-div {
        text-align: left;
    }
    .specific_org_info_banner {
        width: 80px;
        height: 80px;
    }
    .specific_org_name-div {
        position: relative;
        bottom: 105px;
        left: 30px;
    }
    .specific_org_contact-div {
        position: relative;
        bottom: 90px;
        padding-left: 1rem!important;
    }
    .specific_org_total_amount-div {
        position: relative;
        bottom: 130px;
        padding-left: 1rem!important;
    }
    .specific_org_donate-div {
        position: relative;
        bottom: 135px;
        right: 0px;
    }
    .contact-h1 {
        margin-bottom: .5rem!important;
    }
    .org_contact_details {
        margin-bottom: .5rem!important;
    }
    #sp_share-btn {
        width: 80px;
    }
    #sp_share-btn > p {
        font-size: 12px;
    }
    #share-icon {
        margin-left: -18px;
        width: 13px;
        height: 12px;
    }
    .con_share-grp {
        width: 260px;
    }
    #sp_donate-btn {
        width: 260px;
    }
}

