.add-payment-type-inner-2 {
    margin-top: 32px;
    margin-left: 29px;
    margin-right: 21px;
    padding-top: 51px;
    color: #263238;
    font-family: Montserrat;
}

.cc-number {
    margin-top: 21px;
}

.cc-input-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.cc-input {
    height: 36px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    background: #FDFDFD;
    border: 1px solid #C5E8CF;
    box-sizing: border-box;
    border-radius: 4px;
}

.multi-container {
    margin: unset;
    padding: unset;
}

.add-card {
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    background: #67DB88;
    border-radius: 2px;
    border: unset;
    color: white;
}

.back-btn-container {
    width: 100%;
    text-align: center;
}