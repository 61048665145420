#review_modal .modal-content {
    width: 330px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
    border-radius: 4px;
    margin-top: 10rem!important;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.review_content {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
}

.review_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #263238;
    margin-top: 15px;
    padding: 1rem;
}

.contact_number_txt {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #263238;
    margin-top: 10px;
    margin-bottom: 10px;
}

.rate_txt {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #90AD98;
    margin-top: 5px;
}

.your_review_txt {
    height: 130px!important;
    width: 100%;
    background: #F1FBF0;
    border: 1px solid #C5E8CF;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: justify;
    overflow: scroll;
    padding: 1rem 1rem;
}

.your_review_txt::-webkit-scrollbar {
    display:none;
}

.review_actions_btns-div {
    width: 100%;
    margin-top: 15px;
}

#cancel_review-btn {
    width: 100px!important;
    height: 40px!important;
    border: 1px solid #67DB88;
    box-sizing: border-box;
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.06em;
    color: #67DB88;
    background: #FFFFFF;
    text-transform: unset;
    padding: 0px;
}

#post-btn {
    width: 120px;
    height: 40px;
    background: #67DB88;
    border-radius: 2px;
    margin-left: auto;
}

#post-btn > p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    text-transform: lowercase;
}

#post-btn > p::first-letter {
    text-transform: uppercase;
}

#post-icon {
    float: left;
}

@media (max-width: 720px) {
    #post-btn {
        width: 120px;
    }
    #post-btn > p {
        font-size: 16px;
    }
}

