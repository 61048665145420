.Toastify__toast--success {
    background: #FFFFFF !important;
    border: 1px solid #67DB88 !important;
    box-sizing: border-box !important;
    box-shadow: 0px 2px 12px rgba(124, 177, 255, 0.25) !important;
    border-radius: 4px !important;
}

.Toastify__toast--error {
    background: #FFFFFF !important;
    border: 1px solid #FF7E7E !important;
    box-sizing: border-box !important;
    box-shadow: 0px 2px 12px rgba(124, 177, 255, 0.25) !important;
    border-radius: 4px !important;
}

.notify-container {
    display: flex;
}

.notify-img {
    width: 28.33px;
    height: 28.33px;
    margin-right: 11px;
}

.notify-text-container {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #263238;
}

.notify-message-success {
    margin-top: 5px;
}

.notify-text-inner {
    margin-top: -6px;
}

.notify-title-error {
    color: #FF7E7E;
}

.notify-title-success {
    color: #67DB88;
}

.Toastify__close-button {
    display: none;
}