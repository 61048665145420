#reset_password_success-section {
  padding: 0;
}

#reset_password_success-section,
.reset_password_success_div {
  height: 900px;
}

.reset_password_success_inner {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.reset_password_success_item {
  background-image: url(../../images/reset-password-no-green.png);
  background-repeat: no-repeat;
  height: 900px;
  width: 100%;
}

#reset_password_success-section .reset_password_success_inner .reset_password_success_item {
  background-size: 100% 100%;
}

@media (max-width: 720px) {
  #reset_password_success-section,
  .reset_password_success_div {
    height: 800px;
  }

  .reset_password_success_item {
    background-image: url(../../images/reset-password-no-green-mobile.png);
    background-repeat: no-repeat;
    height: 835px;
    width: 100%;
  }
}

#reset_password_success_nav-div {
  padding-top: 45px;
}

@media (max-width: 720px) {
  #reset_password_success_nav-div {
    margin-top: -25px;
  }
}

#nav_second_col-div {
  margin-top: 23px;
}

.contact_us_link {
  font-family: 'Montserrat';
  font-style: normal;
  background-color: transparent;
  border: transparent;
  color: #7CB1FF;
  font-weight: 500;
  font-size: 12px;
  margin-right: 92px;
}

@media (max-width: 720px) {
  .contact_us_link {
      margin-top: 2px;
      margin-right: 30px;
  }
}

.sadaqa-logo {
  width: 138px;
  height: 106px;
}

#nav_logo-div {
  margin-top: -70px;
}

@media (max-width: 720px) {
  #nav_logo-div {
      margin-top: 10px;
  }
}

#main-div {
  margin-top: 90px;
}

@media (max-width: 720px) {
  #main-div {
    margin-top: 50px;
  }
}

.reset_success_image {
  height: 262px;

  margin-bottom: -70px;
}

.reset_success_subtitle {
  height: 22px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #67db88;
  margin-top: 120px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 720px) {
  .reset_success_subtitle {
    margin-top: 120px;
  }
}

.reset_success_subtitle1 {
  width: 400px;
  height: 66px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 155%;
  text-align: center;
  color: #494949;
  position: relative;
  top: 10px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 720px) {
  .reset_success_subtitle1 {
    top: 20px;
  }
}

#reset_pass_to_login-btn {
  width: 228px;
  height: 40px;
  background: #67db88;
  border-radius: 2px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.06em;
  color: #fdfdfd;
  padding: 0px;
  text-transform: unset;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: -10px;
}

.to_login:hover {
  text-decoration: none;
}

@media (max-width: 720px) {
  #reset_pass_to_login-btn {
    margin-top: -5px;
  }
}
