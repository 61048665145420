#main {
  padding: 0;
}

#main, .main-section {
  height: 1132px;
}

.main-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.item {
  background-image: url(../../images/sadaqasbg.png);
  background-repeat: no-repeat;
  height: 1132px;
}

#main .main-inner .item {
  background-size: 100% 1132px;
}

@media (max-width: 720px) {
  #main, .main-section {
    height: 1132px;
  }
  .item {
    background-image: url(../../images/sadaqasbg.png);
    background-repeat: no-repeat;
    height: 1132px;
  }
  #main .main-inner .item {
    background-size: cover;
    background-position: center center;
    text-align: center;
  }
}

#top_content-div {
  padding-top: 50px;
}

.sadaqa-logo {
  width: 138px;
  height: 106px;
}

#login-btn {
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  width: 110px;
  height: 35px;
  color: #8DE4A6;
  border-radius: 4px;
}

#register-btn {
  background-color: transparent;
  border: 1px solid #FFFFFF;
  width: 170px;
  height: 35px;
  color: #FFFFFF;
  border-radius: 4px;
}

#register-btn:hover {
  color: #fff;
  background-color: #7cb1ff;
  border: 1px solid #7cb1ff;
}

#login-btn, #register-btn {
  text-transform: lowercase;
  font-size: 14px;
  padding: 0px;
}

#login-btn::first-letter {
  text-transform: uppercase;
}

#register-btn::first-letter {
  text-transform: uppercase;
}

#login-btn:hover {
  background-color: transparent;
  color: #fff;
}

.auth-btns {
  margin-top: 33px;
}

.authentication_top_link {
  float: right!important;
  margin-right: 15px;
}

@media (max-width: 720px) {
  #top_first-div, #top_third-div {
    display: none;
  }
  #top_content-div {
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 25px;
  }
}

#about_content-div {
  position: relative;
  margin-top: 100px;
}

.main-title {
  color: #FFFFFF;
  text-transform: lowercase;
  font-size: 50px;
  font-weight: bold;
}

.main-title::first-letter {
  text-transform: uppercase;
}

.main-desc {
  color: #FFFFFF;
  text-transform: lowercase;
  text-align: center;
  font-size: 30px;
  font-weight: lighter;
}

.main-desc::first-letter {
  text-transform: uppercase;
}

.main-title, .main-desc {
  margin-top: 20px;
  letter-spacing: 0em;
}

@media (max-width: 720px) {
  #about_content-div {
    justify-content: center;
    align-items: center;
    bottom: 70px;
  }
  .main-title {
    width: 270px;
    font-size: 30px;
    line-height: 37px;
    margin: 25px auto;
  }
  .main-desc {
    margin: 0 auto;
    width: 280px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}

#start_content-div {
  position: relative;
  margin-top: 100px;
}

.start_register_card {
  background-color: transparent;
  border: transparent;
}

.individuals_start-h3, .non_profit-h3 {
  color: #FFFFFF;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0em;
  font-size: 30px;
  margin-bottom: 20px;
}

#get_started-btn {
  height: 35px;
  width: 158px;
  border-radius: 4px;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  color: #8DE4A6;
  padding: 0px;
  font-size: 14px;
}

#start_campaign-btn {
  height: 35px;
  width: 218px;
  border-radius: 4px;
  background-color: #FFFFFF;
  border: 1px solid hsl(0, 0%, 100%);
  color: #8DE4A6;
  padding: 0px;
  font-size: 14px;
  left: calc(47% - 174px/2);
}

.start_register {
  margin-bottom: 90px;
}

#get_started-btn:hover, #start_campaign-btn:hover {
  background-color: transparent;
  color: #fff;
}

.startRegisterLink:hover {
  text-decoration: none;
}

.login_link {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0em;
  margin-top: 20px;
  color: #FFFFFF;
}

.login_url {
  color: #FFFFFF;
  text-decoration: underline;
}

.login_url:hover {
  color: #fff;
}

@media (max-width: 720px) {
  #start_content-div {
    justify-content: center;
    align-items: center;
    bottom: 125px;
  }
  .individuals_start-h3, .non_profit-h3 {
    font-size: 29px;
  }
  .login_link {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
}

#how_it_works-div {
  margin-top: 10px;
  position: relative;
}

.work_h3 {
  width: max-content;
  letter-spacing: 0em;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 49px;
  text-align: left;
  position: relative;
}

.work_desc {
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  color: #FFFFFF;
  font-size: 26px;
  line-height: 32px;
  margin-top: 32px;
  letter-spacing: 0em;
  position: relative;
}

.learn_more {
  font-style: normal;
  font-weight: normal;
  text-transform: lowercase;
  text-align: left;
  color: #FFFFFF;
  font-size: 20px;
  line-height: 24px;
  margin-top: 28px;
  letter-spacing: 0em;
}

.learn_more::first-letter {
  text-transform: uppercase;
}

.arrow-sign {
  width: 58.69px;
  height: 60px;
  margin-top: 2px;
}

#visit-btn {
  height: 35px;
  width: 170px;
  border-radius: 4px;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  color: #7CB1FF;
  text-transform: lowercase;
  font-size: 14px;
  margin-left: 20px;
  margin-top: 32px;
  padding: 0px;
}

#visit-btn::first-letter {
  text-transform: uppercase;
}

#visit-btn:hover {
  color: #fff;
  background-color: #7cb1ff;
  border: 1px solid #fff;
}

@media (max-width: 720px) {
  #how_it_works-div {
    justify-content: center;
    align-items: center;
    bottom: 100px;
  }
  .work_h3 {
    font-size: 30px;
    line-height: 37px;
    top: 50px;
  }
  .work_desc {
    width: 278px;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    top: 30px;
  }
  .learn_more {
    width: 104px;
    font-size: 18px;
    line-height: 22px;
  }
}

.first_item {
  height: 844px;
}

.second_item {
  height: 288px;
}

@media (max-width: 720px) {
  .card_second_col {
    position: relative;
    bottom: 30px;
  }
}