.review-item-container {
    height: 242px;
    font-family: Montserrat;
    font-style: normal;
    color: #263238;
    max-width: 670px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(124, 177, 255, 0.25);
    border-radius: 4px;
    padding: 22px 34px;
}

.review-rounded-img {
    border: 1px solid #67DB88;
    box-sizing: border-box;
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.review-reviewer {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.review-option-dropdown {
    border: unset !important;
    box-shadow: unset !important;
}

.review-option-dropdown-menu {
    border: 1px solid #7CB1FF !important;
    transform: translate(-130px, 24px) !important;
}

.review-option-dropdown-menu .dropdown-item {
    background-color: unset !important;
    background-image: unset !important;
    background-repeat: unset !important;
    background-position: unset !important;
}

.review-item-text {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin-top: 21px;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}