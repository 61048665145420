#recurring_donation-div {
    padding: 0px;
    width: 100%;
}

@media (max-width: 720px) {
    .recurring_donation_row {
        display: flex;
        flex-direction: column-reverse;
    }
}