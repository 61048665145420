.remove_payment_modal .modal-dialog {
    width: 330px;
    height: 220px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(124, 177, 255, 0.25);
    border-radius: 4px;
    -webkit-transform: translate(0,-50%)!important;
    -o-transform: translate(0,-50%)!important;
    transform: translate(0,-50%)!important;
    top: 50%;
    margin: 0 auto;
}

.rp_modal-h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #263238;
    text-transform: unset;
    margin-top: 10px;
}

.rp_input-lbl {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 180%;
    color: #263238;
}

.rp_input {
    background: #EAFFF0;
    border: 1px solid #C5E8CF;
    box-sizing: border-box;
    border-radius: 4px;
    height: 36px;
}

.remove_card-btn {
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    border: unset;
    color: white;
    background: #FF7E7E;
    border-radius: 2px;
}