.trending_tabs {
    display: block;
    width: 100%;
    height: 100%;
}

@media (max-width: 720px) {
    .trending_tabs {
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
}