.donations_tabs {
  /* position: absolute; */
  top: 105px;
  left: unset;
  display: block;
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
}

.react-tabs__tab-panel {
  padding-right: 0px;
}

@media only screen and (max-width: 400px) {
  .donations_tabs {
    width: 100%;
    left: 0px;
  }
}

.react-tabs__tab-list {
  margin-bottom: 0px;
  border-color: #67db88;
  list-style: none;
  display: flex;
  padding-inline-start: 15px;
  margin-top: -75px;
  z-index: 2;
  position: absolute;
}

.react-tabs__tab[aria-selected='true'] {
  background: #eefff3;
  border-color: #67db88;
  border-radius: 0;
}

.react-tabs__tab[aria-selected='false'] {
  border: none;
}

.react-tabs__tab {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #67db88;
  margin-right: 12px;
}

@media (max-width: 720px) {
  .react-tabs__tab-list {
    margin-left: -10px;
  }
  .react-tabs__tab[aria-selected='false'] {
    background: #ffffff;
    border-bottom: #67db88 solid 1px;
  }
}