@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
body {
  padding: 0px;
  margin: 0px;
  max-width: 100%;
  overflow-x: hidden;
  font-family: 'Montserrat';
}

p {
  margin-bottom: 0;
}

#root {
  width: 100%;
}

.custom-scrollable div {
  /*  scrollbar-color: #009FDB transparent; */
  scrollbar-color: #7cb1ff transparent;
  scrollbar-width: thin !important;
}

.custom-scrollable ::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

/* Track */

.custom-scrollable ::-webkit-scrollbar-track {
  border-radius: 10px !important;
  background: #d9e8ff !important;
}

/* Handle */

.custom-scrollable ::-webkit-scrollbar-thumb {
  background: #7cb1ff !important;
  border-radius: 10px !important;
}

/* Handle on hover */

.custom-scrollable ::-webkit-scrollbar-thumb:hover {
  background: #6aa4fa !important;
  width: 6px !important;
}

.react-date-picker__wrapper {
  border: unset !important;
}

.date-display .react-date-picker__inputGroup__divider {
  display: none;
}

.date-display .react-date-picker__inputGroup__input:invalid {
  background: unset;
}

.last-child:last-child {
  margin-left: 40px;
}

@media only screen and (max-width: 992px) {
  .last-child:last-child {
    margin-left: 0px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.text-green-light {
  color: #90ad98;
}

.modal-content {
  border: 0;
}

.modal-content>* {
  width: auto;
}

.bank-info {
  background-color: #eefff3;
  padding: 1rem;
  border: 1px solid #c5e8cf;
  border-radius: 5px;
}

.radio-bank {
  accent-color: #33a533;
}

/* .modal {
  position: absolute;
  top: 50%;
  left: 50%;
} */

.text-md {
  font-size: 1.2rem;
}

.text-sm {
  font-size: 0.85rem;
}

.text-lg {
  font-size: 1.5rem;
}

.text-xxs {
  font-size: 0.75rem;
}

.mobile-resp {
  margin-left: 240px;
  overflow: hidden;
  height: 100vh;
}

.burger {
  display: none;
  z-index: 10;
}

@media only screen and (max-width: 700px) {
  .text-md {
    font-size: 1rem;
  }
  .text-sm {
    font-size: 0.7rem;
  }
  .text-lg {
    font-size: 1.3rem;
  }
  .mobile-hidden {
    display: none;
  }
  .mobile-resp {
    margin-left: 0;
  }
  .burger {
    display: block;
    position: absolute;
    top: 2rem;
    right: 1rem;
    padding: 10px;
    margin: -10px;
  }
  .sidebar {
    z-index: 11;
  }
}

@media only screen and (max-width: 480px) {
  .text-md {
    font-size: 0.8rem;
  }
  .text-sm {
    font-size: 0.65rem;
  }
  .text-lg {
    font-size: 1.1rem;
  }
  .text-xxs {
    font-size: 0.6rem;
  }
}

.my-xs-4 {
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .my-xs-4 {
    margin: 1.5rem 0;
  }
}

.loading-label {
  margin: 20px auto;
  max-width: fit-content;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.tracking-normal {
  letter-spacing: normal;
}

.grid-contributor {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  column-gap: 30px;
  row-gap: 30px;
}

.cont-li {
  list-style: none;
  background-color: white;
  padding: 0.5rem 0.8rem;
  cursor: pointer;
  border: 1px solid #67db88;
  color: #67db88;
  margin: 0 0.1rem;
}

.cont-li:hover {
  color: white;
  background-color: #67db88;
}

.cont-li-active {
  color: white;
  background-color: #67db88;
}

.container-main {
  overflow-y: auto !important;
}

.text-normal {
  text-transform: none;
}

.link-reset:hover {
  text-decoration: none;
}

.org-tabs {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 0;
}

.org-tabs>li {
  padding: 1rem 2rem;
  color: #90ad98;
  border-bottom: 1px solid #ddf3e3;
  letter-spacing: normal;
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .org-tabs>li {
    padding: 1rem 0.5rem;
  }
}

.org-tabs>li:hover {
  color: #7cb1ff;
  border-bottom: 1px solid #67db88;
}

.active-org-tab {
  color: #7cb1ff !important;
  border-bottom: 1px solid #67db88 !important;
  font-weight: 600;
}

.grid-pill {
  display: inline-grid;
  grid-template-columns: auto auto auto;
  row-gap: 5px;
}

.grid-about {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
}

.grid-images-admin {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 20px;
}

@media only screen and (max-width: 480px) {
  .grid-images-admin {
    grid-template-columns: 1fr;
  }
  .grid-images-admin>* {
    place-self: center;
  }
  .text-sm-center {
    text-align: center;
  }
  .mb-sm-4 {
    margin-bottom: 10px;
  }
  .project-img-super {
    width: 50%;
  }
}

.font-semi {
  font-weight: 500;
}

.custom_tooltip .tooltip-inner {
  width: 100px;
  padding: 0.25rem 0.5rem;
  color: #3284ff;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
  border: 1px solid #3284ff;
  border-radius: 4px;
}

.custom_tooltip .arrow::before {
  border-right-color: #3284ff !important;
  background-color: transparent;
}

.cursor-disabled {
  opacity: 50%;
  cursor: not-allowed;
  pointer-events: none;
}

.end_date-tooltip .arrow {
  top: -8px;
}

.end_date-tooltip .tooltip-inner {
  width: 125px;
  padding: 0.25rem 0.5rem;
  color: #3284ff;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
  border: 1px solid #3284ff;
  border-radius: 4px;
}

.end_date-tooltip .arrow::before {
  border-right-color: #3284ff !important;
  background-color: transparent;
}

.dropdown-toggle::after {
  display: none;
}

.highcharts-container {
  width: 100% !important;
  height: 100% !important;
}

#stats_modal .react-date-picker__inputGroup__input {
  background-color: transparent;
}

.z-index-2 {
  z-index: 2 !important;
}