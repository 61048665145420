#register_organization-section {
	padding: 0;
}

#register_organization-section,
.register_organization_section {
  height: 100%;
}

.register_organization_inner {
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  display: none;
}

.register_organization_item {
  background-image: url(../../../images/desktop-register-background.png);
  background-repeat: no-repeat;
  height: 1024px;
  width: 100%;
}

#register_organization-section .register_organization_inner .register_organization_item {
  background-size: 100% 100%;
}

@media (max-width: 720px) {
  #register_organization-section,
  .register_organization_section {
    height: 100%;
  }

  .register_organization_item {
    background-image: url(../../../images/mobile-register-background.png);
    height: 1085px;
    width: 100%;
  }
}

#register_organization_nav-div {
  padding-top: 40px;
}

.sadaqa_logo {
  width: 120px;
  height: 100px;
}

#register_organization_nav-div #contact_us-p {
  position: absolute;
  top: 25px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #FDFDFD;
  margin-left: 215px;
}

@media (max-width: 720px) {
  #register_organization_nav-div #contact_us-p {
    right: 30px;
  }
}

.back_path {
  display: flex;
  margin-top: 95px;
  margin-left: 235px;
}

.back_path .back_button {
  display: block;
}

.back_button {
  width: 61px;
  height: 63px;
}

@media (max-width: 720px) {
  .back_path {
    margin-left: 20px;
  }
}

@media (max-width: 720px) {
  #register_organization-form {
    position: relative;
    bottom: 50px;
  }
}

.register_organization_title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 180%;
  color: #263238;
  text-transform: unset;
  letter-spacing: 0em;
}

.back_path .register_organization_title {
  margin: 15px;
}

.RSPBprogressBar {
  width: 78px;
  height: 3px;
  background-color: #D9E8FF;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 60px;
}

#stepper_buttons_step2_div {
  position: relative;
  top: 173px;
  padding: 0px 13% 0px 20%;
  overflow: hidden;
}

.buttons_container {
  display: block;
  text-align: center;
}

@media (max-width: 720px) {
  #stepper_buttons_step2_div {
    top: 75px;
  }
}
/*
.next_button,
.register_button {
  position: relative;
  width: 100px;
  height: 35px;
  background: #67DB88;
  color: #FFFFFF;
  border-radius: 4px;
  text-transform: unset;
  padding: 0px;
  float: right;
  left: 0px;
}

.next_button:hover,
.register_button:hover {
  background: #67DB88;
}

@media (max-width: 720px) {
  .register_button,
  .next_button {
    left: 17px;
  }
}
*/
/*
.back_bottom_btn {
  width: 41px;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #67DB88;
  text-transform: unset;
  padding: 0px;
  background-color: transparent;
  float: left;
}

.back_bottom_btn:hover {
  background-color: transparent;
  color: #67DB88;
}

@media (max-width: 720px) {
  .back_bottom_btn {
    position: relative;
    right: 40px;
    top: 5px;
  }
}
*/

.org_back_btn {
  position: relative;
  width: 41px;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #67DB88;
  text-transform: unset;
  padding: 0px;
  background-color: transparent;
  top: 195px;
  left: 240px;
}

.org_back_btn:hover {
  background-color: transparent;
  color: #67DB88;
}

@media (max-width: 720px) {
  .org_back_btn {
    top: 85px;
    left: 35px;
  }
}

.already_have_an_account {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #263238;
}

.if_exist_login {
  text-transform: unset;
  background-color: transparent;
  border: transparent;
  width: 56px;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #7CB1FF;
  padding: 0px;
  position: relative;
  top: 5px;
  border: transparent!important;
}

.if_exist_login:hover {
  background-color: transparent!important;
  color: #7CB1FF!important;
}
    
#if_exists_step2-div {
  position: relative;
  bottom: 0;
  width: 100%;
  top: 275px;
  padding-bottom: 20px;
}

#if_exists_step2-div a {
  position: relative;
  bottom: 5px;
}

@media (max-width: 720px) {
  #if_exists_step2-div {
    top: 105px;
    padding-bottom: 40px;
  }
}