.org_money_vector {
    width: 18px;
    height: 19px;
}

.org_money_value {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    color: #263238;
}

.next_donation-p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #90AD98;
}

.next_donation_date {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #263238;
}

.donation_status {
    width: 90px;
    height: 22px;
    border-radius: 4px;
    background-color: rgb(124, 177, 255);
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    padding: 3px;
    text-align: center;
}

.next_donation-span {
    display: flex;
}

@media (max-width: 720px) {
    #org_donation_item {
        width: 100%;
    }
    .org_money_value {
        width: max-content;
    }
    .next_donation-p {
        position: relative;
        bottom: 5px;
        /* left: 70px; */
        width: 91px;
    }
    .next_donation_date {
        position: relative;
        top: 5px;
    }
    .next_donation-span {
        display: block;
    }
    .donation_status {
        margin-left: -15px!important;
    }
}
