.add-payment-method-btn {
    width: 100%;
    height: 32px;
    margin-top: 23px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.06em;
    color: #7CB1FF;
    border: 1px solid #7CB1FF;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: white;
}

.add-payment-method-btn:hover {
    background-color: #7CB1FF;
    color: white;
}

.donate-btn {
    width: 100%;
    height: 40px;
    margin-top: 40px;
    border: unset;
    display: block;
    background: #67DB88;
    border-radius: 2px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.06em;
    color: #FDFDFD;
    margin-top: 40px;
}

.back-btn-container {
    text-align: center;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 46px;
}

.back-btn {
    background-color: transparent;
    color: #7CB1FF;
    border: unset;
}

.react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.22) !important;
}