.text-xs {
    font-size: 0.75rem;
}

a.disabled {
    pointer-events: none;
    cursor: default;
}

.form-title-custom {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #263238;
}

.css-2b097c-container {
    width: 284px !important;
}

.supportingCauseSelector {
    width: 284px !important;
}

.bold-600 {
    font-weight: 600;
}

.project-disable-overlay {
    background: linear-gradient(180deg, rgba(255, 126, 126, 0.58) 0%, rgba(255, 200, 200, 0.71) 100%);
    border-radius: 4px;
    top: 30px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: absolute;
    z-index: 2;
}

.project-has-ended-overlay {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 200, 200, 0.71) 100%);
    border-radius: 4px;
    top: 30px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: absolute;
    z-index: 2;
}

.project-disable-overlay-text {
    font-weight: 500;
    font-size: 10px;
    color: #FFFFFF;
    background: #FF7E7F;
    border: 1px solid #FFFFFF;
    border-radius: 2px;
    width: 70px;
    height: 20px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    padding-top: 2px;
}

.project-has-ended-overlay-text {
    font-weight: 500;
    font-size: 10px;
    color: #FF7E7E;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 2px;
    width: 70px;
    height: 20px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.img-object-fit-cover {
    object-fit: cover;
}

.account-img {
    width: 128px;
    height: 128px;
    min-width: 128px;
    min-height: 128px;
    border: 1px solid #67DB88;
    border-radius: 50%;
    margin-top: 10px;
    margin-left: 40px;
}

.project-image-size {
    width: 165px;
    height: 165px;
}

.infinite-scroll-component__outerdiv {
    height: 100%;
}

.react-tel-input .form-control {
    width: 284px;
    height: 36px;
    background: #FDFDFD;
    border: 1px solid #C5E8CF;
    box-sizing: border-box;
    border-radius: 4px;
}

@media (max-width: 720px) {
    .account-img {
        width: 62px;
        height: 62px;
        min-width: 62px;
        min-height: 62px;
        margin-left: 32px;
    }
    .project-image-size {
        width: 90px;
        height: 90px;
    }
}

@media only screen and (max-width: 480px) {
    .container-org-items {
        padding-left: 0px;
        padding-right: 0px;
    }
}