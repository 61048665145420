.Info-project {
  width: 410px;
  height: 650px;

  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(124, 177, 255, 0.25);
  border-radius: 4px;

  background-image: url("../../../images/projectInfoBg.svg");
  background-repeat: no-repeat;
  background-position: top left;
}

.Info-image {
  width: 200px;
  height: 200px;

  margin-top: 40px;

  margin-left: 105px;
  margin-right: auto;
}

.Info-project-title-section {
  margin-top: 20px;

  text-align: center;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #263238;
}

.Info-coin {
  margin-top: 30px;
  margin-left: 60px;
}

.Info-amount-rasied-section {
  margin-top: -25px;
  margin-left: 100px;

  color: #263238;
}

#project-raised-amount {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
}

#Info-of-amount {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  color: #263238;
}
.progress {
  background-color: #d8d8d8;
  border-radius: 20px;

  margin-top: 20px;
  margin-left: 60px;
  width: 282px;
  height: 8px;
}

.progress-done {
  background: #7cb1ff;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

.url-section {
  margin-top: 40px;
  margin-left: 55px;
}

#url-item {
  margin-left: 10px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  /* identical to box height */

  color: #3284ff;

  text-decoration: none;
}

.Info-date-section {
  margin-left: 60px;
  margin-top: 20px;

  color: #263238;
}

#project-ends-on-h {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}

#Info-end-date {
  margin-top: -32px;
  margin-left: 220px;
  color: #67db88;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}

#Info-date-ended {
  margin-top: -32px;
  margin-left: 220px;
  color: red;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}

#icon-on {
  width: 20px;
  margin-left: 70px;
  margin-top: -35px;
  cursor: pointer;
}

#icon-off {
  display: off;
}

.Info-createdate-section {
  margin-top: 20px;
  margin-left: 60px;
}

#Info-created-on-h {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}

#Info-create-date {
  margin-top: -35px;
  margin-left: 220px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}

#Info-donate-btn {
  width: 197px;
  height: 40px;

  margin-top: 30px;

  margin-left: 65px;

  background: #67db88;
  border-radius: 0px;
  border: 1px solid #67db88;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  text-align: center;
  letter-spacing: 0.06em;

  color: #ffffff;

  cursor: pointer;
}

#Info-share-btn {
  margin-left: 20px;
  width: 80px;
  height: 40px;

  border: 1px solid #7cb1ff;
  box-sizing: border-box;
  border-radius: 2px;

  background-color: #fff;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.06em;

  color: #7cb1ff;

  cursor: pointer;
}

#share-icon {
  margin-right: 5px;
}
