.register_individual_step_two {
    position: relative;
    top: 115px;
}

.individual_signup_label {
    font-family: 'Montserrat';
    font-size: 14px;
    letter-spacing: 0px;
    color: #263238;
}

.password_input {
    width: 284px;
    height: 36px;
    background: #fdfdfd;
    border: 1px solid #c5e8cf;
    box-sizing: border-box;
    border-radius: 4px;
}
  
.password_input::placeholder {
    color: #72ae87;
}

.pass_lbl {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #263238;
    display: block;
}

.password_show {
    color: #67db88;
    position: relative;
    left: 255px;
    bottom: 28px;
}

.confirm_password_input_group {
    position: relative;
    bottom: 22px;
}

.required_field {
    color: #ff0000;
}

.fa-eye-slash {
    color: #C5E8CF;
}

.fa-eye::before {
    color: #C5E8CF;
}

.individual_signup_error_step_2 {
    color: red;
    font-size: 10px;
    position: relative;
    bottom: 15px;
}

.register_individual_button {
  position: relative;
  width: 100px;
  height: 35px;
  background: #67DB88;
  color: #FFFFFF;
  border-radius: 4px;
  text-transform: unset;
  padding: 0px;
  float: right;
  left: 260px;
  top: 112px;
}

.register_individual_button:hover {
  background: #67DB88;
}

@media (max-width: 720px) {
  .register_individual_button {
    left: 0px;
    top: 75px;
  }
}
