.no-projects-container {
    width: 100%;
    height: 100%;
    text-align: center;
    color: #263238;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
}

.no-projects-img {
    width: 183.95px;
    height: 177.44px;
    margin-left: auto;
    margin-right: auto;
}

.no-projects-text-1 {
    margin-top: 30px;
}

.no-projects-text-2 {
    margin-top: 7px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.create-new-project-img {
    margin-right: 10px;
}

.create-new-project-btn {
    width: 200px;
    height: 32px;
    background: #7CB1FF;
    display: flex;
    flex-direction: row;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    border: unset;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18px;
}