.project-review-item {
  width: 670px;
  height: 242px;

  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(124, 177, 255, 0.25);
  border-radius: 4px;

  padding-top: 20px;
  padding-left: 20px;
}

.project-title {
  margin-top: -70px;
  margin-left: 90px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.author-field {
  margin-top: 10px;
  margin-bottom: 1px;
  margin-left: 90px;

  text-transform: lowercase;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 5px;
  /* identical to box height */

  color: #90ad98;
}

.project-name-section {
  margin-top: -12px;
  margin-left: 120px;

  height: 20px;

  text-transform: none;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #262326;
}

.project-time-section {
  margin-top: 5px;
  margin-left: 90px;

  width: 80px;
  height: 15px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #263238;
}

.project-dot-icon {
  margin-top: -57px;
  margin-left: 168px;
}

.project-date-section {
  margin-top: -44px;
  margin-left: 175px;

  width: 61px;
  height: 15px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #263238;
}

.project-review-section {
  margin-top: -50px;
  margin-left: 100px;
  height: 100px;
  width: 550px;
  border: #262326;

  margin-top: 25px;
  margin-left: 10px;
  margin-right: 10px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #263238;
}

.project-logo {
  margin-top: 10px;

  width: 70px;
  height: 70px;
}
