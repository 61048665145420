.btn-container {
    width: 100%;
    display: flex;
    background-color: transparent;
    border: unset;
    text-align: left;
    padding: 10px 0px;
}

.btn-container img {
    margin-right: 10px;
    margin-top: 5px;
    width: 50px;
    height: 50px;
    border-radius: 4px;
}

.btn-container-selected img {
    margin-top: 10px;
    width: 59px;
    height: 59px;
}

/* .project-img {
    
} */

.organization-img {
    border: 1px solid #EEFFF3;
    box-sizing: border-box;
    border-radius: 4px;
}

.type {
    margin-top: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    color: #67DA8B;
}

.type-selected {
    font-size: 12px;
    line-height: 12px;
}

.title {
    margin-top: 8px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #263238;
}

.title-selected {
    font-weight: 600;
}

.created-by {
    margin-top: 12px;
    font-family: Montserrat;
    font-style: normal;
    font-size: 10px;
    line-height: 12px;
}

.created-by-title {
    font-weight: normal;
    color: #90AD98;
}

.created-by-text {
    font-weight: 500;
    color: #263238;
}