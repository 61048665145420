.single_password_form {
    height: 30px;
    width: 230px;
}

.single_password_input {
    height: 36px;
    background: #FDFDFD;
    border: 1px solid #C5E8CF;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 5px 15px;
}

.single_password_show {
    color: #67DB88;
    position: relative;
    float: right;
    margin-right: 10px;
    bottom: 27px;
}

.single_password_input::placeholder { 
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #72AE87;
  transform: unset;
}
