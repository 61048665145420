.trending-section {
  position: absolute;
  width: 410px;
  height: 70px;

  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(124, 177, 255, 0.25);
  border-radius: 4px;
}

.trending-ribbon {
  position: absolute;
  top: 15px;
  left: 5px;
}

.trending-field {
  position: relative;
  width: 400px;
  height: 100px;
  left: 75px;
  top: -60px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 17px;
  /* identical to box height */

  color: #263238;
}

.trending-arrow {
  position: relative;
  top: -172px;
  left: 370px;

  cursor: pointer;
}
