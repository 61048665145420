.donate-project-item {
    width: 670px;
    height: 136px;
    background-color: #FFFFFF!important;
    box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
    background: url("../../../images/statBg.svg");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 85px;
}

.donate-project-item-row {
    padding: 20px;
    width: 100%;
}

.donate-project-col-one {
    flex-grow: unset;
    width: 20%;
}

.donate-project-col-two {
    width: 40%;
    padding-left: 30px;
}

.donate_project_logo {
    position: relative;
    bottom: 2px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    z-index: 1;
    object-fit: cover;
    display: block;
}

.donate_project_logo_overlay {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.79) 100%);
    border-radius: 4px;
    top: 0;
    bottom: 23px;
    position: absolute;
    z-index: 2;
    left: unset !important;
    right: unset !important;
    text-align: center;
    width: 100%;
    height: 100%;
}

.donate_project_logo_overlay-text {
    width: 52px;
    height: 22px;
    background: #7CB1FF;
    border-radius: 4px;
    padding: 2px;
    font-weight: 500;
    font-size: 10px;
    color: #ffffff;
    margin: 0;
    position: relative;
    top: 60%;
}

.donate_project_name {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #263238;
    margin-bottom: 15px;
    width: max-content;
}

.donate_value-div {
    display: flex;
    position: relative;
    top: 10px;
}

.project_money_img {
    width: 30px;
    height: 30px;
}

.donate_value-p {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #263238;
}

.donate-project-col-three {
    text-align: right;
    width: 40%;
}

.donate_by {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #90AD98;
    margin-bottom: 5px;
    width: max-content;
}

.donate_by_span {
    color: #263238;
    margin-left: 5px
}

.project_donation_date-div {
    position: relative;
    top: 20px;
}

.project_donation_date-lbl {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #90AD98;
    margin-bottom: 4px;
}

.project_donation_date-txt {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #263238;
}

@media (max-width: 720px) {
    .donate-project-item {
        padding-top: 18px;
        width: 100%;
        height: 125px;
        background: none;
    }
    .donate-project-item-row {
        width: auto;
    }
    .donate-project-col-one {
        width: auto;
    }
    .donate_project_logo {
        bottom: 23px;
    }
    .donate_project_logo_overlay {
        top: -23px;
    }
    .donate-project-col-two {
        position: relative;
        bottom: 23px;
    }
    .donate_project_name {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        margin-top: 0px;
    }
    .donate_by {
        position: relative;
        bottom: 5px;
        font-size: 10px;
        line-height: 12px;
        margin-bottom: 0px;
    }
    .donate_value-p {
        font-size: 14px;
    }
    .donate-project-col-three {
        position: relative;
        bottom: 85px;
        margin-left: auto;
    }
    .project_donation_date-lbl {
        font-size: 10px;
        width: max-content;
        margin-left: auto;
    }
    .project_donation_date-div {
        top: 10px;
    }
    .project_money_img {
        width: 20px;
        height: 20px;
    }
    .donate_value-div {
        top: 19px!important;
    }
}
