.donate_donations_tabs {
    top: 105px;
    left: unset;
    display: block;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
}

.donate_filter-div {
    display: flex;
    width: 670px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}
  
@media (max-width: 720px) {
    .donate_donations_tabs {
        width: 100%;
        left: 0px;
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .donate_filter-div {
        display: block!important;
        width: 100%!important;
        padding-right: 0px;
        padding-left: 0px;
    }
}
