.recurring-item-title {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #263238;
}

.recurring-custom-dropdown {
    width: 100%;
    height: 32px;
    margin-top: 11px;
    background: #FFFFFF;
    border: 1px solid #C5E8CF;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0px;
}

.select-search__value::after {
    display: none !important;
}

.select-search__input {
    background: #FFFFFF !important;
    border: 1px solid #C5E8CF !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    box-shadow: unset !important;
    color: #263238;
}

.select-search__input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #90AD98;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
}

.select-search__input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #90AD98;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
}

.select-search__input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #90AD98;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
}

.select-search__select {
    background: #E9FFEF !important;
    border: 1px solid #C5E8CF !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
}

.select-search__options {
    margin: 0px 15px;
}

.custom-radio {
    width: 100%;
    display: flex;
}

.custom-radio label {
    border: 1px solid #C5E8CF;
    box-sizing: border-box;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: #90AD98;
    background-color: #FFFFFF;
    text-transform: unset;
}

.custom-radio-schedule label {
    flex: 1 !important;
}

.custom-radio-schedule label:first-child {
    border-radius: 2px 0px 0px 2px;
    border-right: unset;
}

.custom-radio-schedule label:last-child {
    border-radius: 0px 2px 2px 0px;
    border-left: unset;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle, .btn-primary:hover, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle, .btn-outline-primary:hover {
    background: #68D990;
    font-style: normal;
    font-weight: 600;
    color: #FFFFFF;
    border: 1px solid #C5E8CF;
}

.custom-radio-amount-container {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 10px);
}

.custom-amount-radio {
    border: 1px solid #C5E8CF;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 10px;
    flex: 1 0 30% !important;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
}

.other-text {
    text-align: center;
    flex: 1 0 30% !important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: #263238;
    margin-top: 15px;
    letter-spacing: 0em;
}

.other-container {
    /* display: flex;
    flex-wrap: wrap; */
    margin-top: 11px;
}

.other-input {
    flex: 1 0 60% !important;
    margin-right: 0px;
    border: 1px solid #C5E8CF;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #263238;
    height: 40px;
}

.next-btn {
    width: 66px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #7CB1FF;
    box-sizing: border-box;
    border-radius: 2px;
    text-align: center;
    color: #7CB1FF;
    float: right;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.next-btn:hover {
    background-color: #7CB1FF;
    color: #FFFFFF;
}

.donate-h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #7CB1FF;
    text-transform: unset;
    letter-spacing: normal;
    margin-bottom: 15px;
}

.org_name-h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #263238;
    text-transform: unset;
    letter-spacing: normal;
    margin: 3px 20px;
    padding: 20px 0px;
}

.recuring_type_opt {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #263238;
}

.select_donate_type {
    width: 154px !important;
    min-width: 154px;
    max-width: 154px;
    height: 36px;
    background: #E9FFEF;
    border: 1px solid #C5E8CF;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #90AD98;
    padding-left: 5px;
    bottom: 5px;
    position: relative;
}

.donate_org_vector {
    width: 60px;
    height: 60px;
    border: 1px solid #67DB88;
    box-sizing: border-box;
}

@media (max-width: 720px) {
    .recuring_type_opt {
        font-size: 12px;
    }
    .select_donate_type {
        width: 125px !important;
        min-width: 125px;
        max-width: 125px;
    }
}