.project-updates-page {
  background-color: #eefff3;
  height: 100vh;

  overflow: hidden;
}

#updates-project-info {
}

#updates-project-item {
}
