#login {
  height: 100vh;
}

#login,
.login-section {
  height: 100vh;
}

.login-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.login_item {
  background-image: url(../../images/desktop-log-in-background.png);
  background-repeat: no-repeat;
  height: 100vh;
  background-size: cover;
}

#login .login-inner .login_item {
  background-size: contain;
  background-position: center;
}

@media (max-width: 720px) {
  #login,
  .login-section {
    height: 812px;
    overflow: hidden;
  }

  .login_item {
    background-image: url(../../images/mobile-log-in-background.png);
    background-repeat: no-repeat;
    height: 860px;
    width: 100%;
  }
  #login .login-inner .login_item {
    background-size: cover;
  }
}

#login_nav_content-div {
  padding-top: 40px;
}

@media (max-width: 720px) {
  #login_nav_content-div {
    margin-top: -25px;
  }
}

.back-button {
  width: 61px;
  height: 63px;
  margin-left: 95px;
}

.back-button:hover {
  background-color: transparent;
}

@media (max-width: 720px) {
  .back-button {
    margin-left: 0px;
  }
}

#nav_content_second-div{
  margin-top: 23px;
}

.contact-us-link {
  font-style: normal;
  background-color: transparent;
  border: transparent;
  color: #7CB1FF;
  font-weight: 500;
  font-size: 12px;
  margin-right: 92px;
  letter-spacing: 0px;
}

@media (max-width: 720px) {
  .contact-us-link {
    margin-top: -57px;
    margin-right: 20px;
  }
}

.sadaqa-logo {
  width: 138px;
  height: 106px;
}

#nav_content_third-div {
  margin-top: -70px;
}

@media (max-width: 720px) {
  #nav_content_third-div {
    margin-top: -15px;
  }
}

#login_form-div {
  margin-top: 30px;
}

@media (max-width: 720px) {
  #login_form-div {
    margin-top: 35px;
  }
}

.wrapper {
  margin: 0 auto;
  display: inline-block;
  position: relative;
  top: 5px;
}

.login_title {
  font-style: normal;
  text-transform: lowercase;
  float: left;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0em;
  color: #67DB88;
}

.login_title::first-letter {
  text-transform: uppercase;
}

.heart_logo {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
}

.login_text {
  font-style: normal;
  font-weight: normal;
  color: #263238;
  font-size: 10px;
  letter-spacing: 0em;
  margin-bottom: 15px;
}

.login_text::first-letter {
  text-transform: uppercase;
}

.login_input {
  margin-bottom: 10px;
}

.email_input {
  width: 230px;
  height: 36px;
  background: #FDFDFD;
  border: 1px solid #C5E8CF;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 5px 15px;
}

.email_input::placeholder { 
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #72AE87;
  transform: unset;
}

#toggle-btn {
  background: #FDFDFD;
  border: 1px solid #C5E8CF;
  border-right-color: transparent;
  margin-left: -4px;
  color: #67DB88;
  transform: rotateY(180deg);
}

#forget_pass-a {
  text-align: right;
  position: relative;
  left: 30px;
}

.forget_pass {
  font-style: normal;
  font-weight: normal;
  color: #67DB88;
  font-size: 10px;
  line-height: 180%;
  margin-bottom: 15px;
  letter-spacing: 0px;
}

.login_button {
  height: 40px;
  background: #67DB88;
  border-radius: 2px;
  width: 228px;
  letter-spacing: 0em;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 0px;
}

.login_button:hover {
  color: #fff;
  background-color: #67DB88;
}

#social_network_login-div {
  margin-top: 8px;
}

@media (max-width: 720px) {
  #social_network_login-div {
    margin-top: 10px;
  }
}

/* .continue_with {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 180%;
  color: #263238;
  letter-spacing: 0em;
  text-transform: unset;
}

#facebook-btn {
  background: #395185;
  border-radius: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #FDFDFD;
  letter-spacing: 0em;
  width: 110px;
  height: 46px;
  padding: 12px;
}

#facebook-btn:hover {
  background: #395185;
  color: #FDFDFD;
}

#google-btn {
  background: #FF7E7E;
  border-radius: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #FDFDFD;
  letter-spacing: 0em;
  width: 110px;
  height: 46px;
  padding: 12px;
}

#google-btn:hover {
  background: #FF7E7E;
  color: #FDFDFD;
}

.social_button_text {
  margin-top: -2px;
  margin-left: 30px;
} */

#email-btn {
  background: #FFFFFF;
  border: 1px solid #7CB1FF;
  border-radius: 2px;
  width: 230px;
  height: 40px;
}

#send_email-icon {
  float: left;
}

#email-btn > p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #7CB1FF;
  letter-spacing: 0em;
  text-transform: lowercase;
  margin-top: -3px;
}

#email-btn > p::first-letter {
  text-transform: uppercase;
}

.icon {
  font-size: 20px;
  float: left;
}

#login_bottom_content-div {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 30px;
}

@media (max-width: 720px) {
  #login_bottom_content-div {
    position: unset;
    margin-top: 175px;
    padding: 0;
  }
}

.login_bottom_card p {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #FDFDFD;
  margin-bottom: 8px;
}

#register_organization-btn {
  width: 228px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 2px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #67DB88;
  text-transform: unset;
  letter-spacing: 0em;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 11px 0px;
}

.to_register_individual:hover,
.to_register_organization:hover {
  text-decoration: none;
}

.to_forgot_password:hover {
  text-decoration: none;
}
