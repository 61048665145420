.payment-method-container {
    height: 40px;
    margin-top: 10px;
    background: #FFFFFF;
    border: 1px solid #C5E8CF;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 0px;
}

.radio-input {
    float: right;
    position: unset;
    margin-right: 10px;
    margin-top: 13px;
    background: #FFFFFF;
    box-sizing: border-box;
    cursor: pointer;
}

.payment-method-container input[type='radio'] {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    outline: none;
    border: 1px solid #67DB88;
}

.payment-method-container input[type='radio']:before {
    content: '';
    display: block;
    width: 70%;
    height: 70%;
    margin: 17% auto;
    border-radius: 50%;
}

.payment-method-container input[type="radio"]:checked:before {
    background: #67DB88;
}

.payment-method-container input[type="radio"]:checked {
    border: 1px solid #67DB88;
}

.radio-label {
    width: calc(100% - 25px);
    height: 100%;
    display: inline-flex;
    padding-top: 9px;
    padding-left: 1.25rem;
    cursor: pointer;
}

.radio-label-img-container {
    width: 32px;
    text-align: center;
}

.radio-label-text {
    margin-left: 18px;
    margin-top: 5px;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
}

.radio-label-text-validity {
    margin-left: auto;
    margin-right: 10px;
    margin-top: 7px;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: right;
}