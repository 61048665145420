.landing-section-2-3 {
    background: url("../images/landing_section_2_3_bg.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 15;
    position: relative;
    padding-bottom: 50px;
}

.landing-section-2-3-bg-img {
    position: absolute;
}

.landing-section-2-3-bg-section {
    background-color: transparent;
    z-index: 15;
    position: absolute;
}