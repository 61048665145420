.container-main {
  margin: 0px;
  padding-left: 30px;
  padding-right: 40px;
  max-width: unset;
  display: flex;
  background-color: #eefff3;
  padding-top: 40px;
  /* height: 125vh; */
  /* overflow-y: hidden; */
  overflow-x: hidden;
  padding-bottom: 40px;
}

.custom_tabs {
  display: flex;
}

.custom_nav_title {
  text-transform: unset;
  letter-spacing: normal;
}

.custom_subnav_title {
  position: relative;
  height: 22px;
  left: 55px;
  top: 49px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #9bb1a1;
}

.header-back-btn {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #263238;
  background-color: transparent;
  border: unset;
  background: transparent;
  margin-bottom: 10px;
}

.breadcrumb {
  margin-bottom: unset;
}

@media (max-width: 720px) {
  .container-main {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .main-header {
    /* height: 88px !important; */
    background-size: contain !important;
  }
  .main-header .tab-title {
    font-size: 18px !important;
  }
  .main-header-breadcrumb {
    /* margin-top: 10px !important; */
    font-size: 12px !important;
    padding-left: 10px;
  }
  .main-header-breadcrumb .breadcrumb {
    margin-bottom: 0px !important;
    padding-bottom: 5px !important;
  }
  .header-back-btn {
    font-size: 18px !important;
    margin-left: 10px;
  }
}
.mobile-header-resp {
  margin-top: unset;
}
@media only screen and (max-width: 480px) {
  .mobile-header-resp {
    margin-top: 5px !important;
  }
}
