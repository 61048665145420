.register_individual_step_one {
    position: relative;
    top: 115px;
}

.input_field {
    width: 284px;
    height: 36px;
    background: #FDFDFD;
    border: 1px solid #C5E8CF;
    box-sizing: border-box;
    border-radius: 4px;
}

.required_field {
    color: #ff0000;
}

.individual_signup_label {
    font-family: 'Montserrat';
    font-size: 14px;
    letter-spacing: 0px;
    color: #263238;
}

.individual_signup_error_step_1 {
    color: red;
    font-size: 10px;
    margin-top: 5px;
}

.next_button {
    position: relative;
    width: 100px;
    height: 35px;
    background: #67DB88;
    color: #FFFFFF;
    border-radius: 4px;
    text-transform: unset;
    padding: 0px;
    float: right;
    left: 80px;
    top: 115px;
}
  
.next_button:hover {
    background: #67DB88;
}

@media (max-width: 720px) {
    .next_button {
        left: 0px;
        top: 20px;
    }
}

.city_zip-div {
    width: 284px;
    display: flex;
    justify-content: space-between;
}

.city_txt {
    width: 169px!important;
}

.zip_code_txt {
    width: 100px!important;
    margin-left: auto;
}