#forgot_password {
  padding: 0;
}

#forgot_password,
.forgot_password-section {
  height: 905px;
}

.forgot_password-inner {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.forgot_password_item {
  background-image: url(../../images/reset-password-background.png);
  background-repeat: no-repeat;
  height: 905px;
  width: 100%;
}

#forgot_password .forgot_password-inner .forgot_password_item {
  background-size: 100% 100%;
}

@media (max-width: 720px) {
  #forgot_password,
  .forgot_password-section {
    height: 746px;
  }

  .forgot_password_item {
    background-image: url(../../images/mobile-log-in-background.png);
    background-repeat: no-repeat;
    height: 850px;
    width: 100%;
  }
}

#forgot_password_nav_content-div {
  padding-top: 40px;
}

@media (max-width: 720px) {
  #forgot_password_nav_content-div {
    margin-top: -25px;
  }
}

.back-button {
  width: 61px;
  height: 63px;
  margin-left: 95px;
}

.back-button:hover {
  background-color: transparent;
}

@media (max-width: 720px) {
  .back-button {
    margin-left: 0px;
  }
}

#nav_content_second-div {
  margin-top: 23px;
}

.contact-us-link {
  font-family: "Montserrat";
  font-style: normal;
  background-color: transparent;
  border: transparent;
  color: #7cb1ff;
  font-weight: 500;
  font-size: 12px;
  margin-right: 92px;
  letter-spacing: 0px;
}

@media (max-width: 720px) {
  .contact-us-link {
    margin-top: -57px;
    margin-right: 20px;
  }
}

.sadaqa-logo {
  width: 138px;
  height: 106px;
}

#nav_content_third-div {
  margin-top: -70px;
}

@media (max-width: 720px) {
  #nav_content_third-div {
    margin-top: -15px;
  }
}

#forgot_password_form-div {
  margin-top: 85px;
}

@media (max-width: 720px) {
  #forgot_password_form-div {
    margin-top: 55px;
  }
}

.forgot_password_title {
  width: 178px;
  height: 18px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #67DB88;
  letter-spacing: 0em;
  text-transform: unset;
}

.forgot_password_text {
  width: 228px;
  height: 24px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #263238;
  letter-spacing: 0em;
  text-transform: unset;
  margin-bottom: 20px;
}

.email_input {
  width: 230px;
  height: 36px;
  background: #FDFDFD;
  border: 1px solid #C5E8CF;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 5px 15px;
  margin-bottom: 8px;
}

.email_input::placeholder {
  width: 115px;
  height: 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #72AE87;
  text-transform: unset;
}

.forgot_password_button {
  width: 228px;
  height: 40px;
  background: #67DB88;
  border-radius: 2px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FDFDFD;
  text-transform: unset;
  letter-spacing: 0em;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 11px 0px;
}

.forgot_password_button:hover {
  color: #fff;
  background-color: #67db88;
}

.back_to_sign_in {
    width: 105px;
    height: 17px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #7CB1FF;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18px;
}

.back_to_sign_in:hover {
  text-decoration: none;
  color: #7CB1FF;
}

#sign_up_as_individual-div {
  margin-top: 35px;
}

@media (max-width: 720px) {
  #sign_up_as_individual-div {
    margin-top: 75px;
  }
}

.no_account {
  width: 178px;
  height: 27px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 180%;
  color: #263238;
  text-transform: unset;
  letter-spacing: 0em;
}

#sign_up_as_individual-btn {
  width: 230px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #7CB1FF;
  box-sizing: border-box;
  border-radius: 2px;
}

#send_email-icon {
  float: left;
}

#sign_up_as_individual-btn > p {
  position: relative;
  left: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7CB1FF;
  text-transform: lowercase;
  padding: 1px 0px;
}

#sign_up_as_individual-btn > p::first-letter {
  text-transform: uppercase;
}

#forgot_password_bottom_content-div {
  margin-top: 240px;
}

@media (max-width: 720px) {
  #forgot_password_bottom_content-div {
    margin-top: 95px;
  }
}

.forgot_password_bottom_card p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #fdfdfd;
}

#register_organization-btn {
  width: 228px;
  height: 40px;
  background: #ffffff;
  border-radius: 2px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #67db88;
  text-transform: unset;
  letter-spacing: 0em;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 11px 0px;
}

.to_register_individual:hover,
.to_register_organization:hover,
.to_reset_password:hover {
  text-decoration: none;
}

.forgot_password_error {
  color: red;
  font-size: 10px;
  margin-bottom: 8px;
}

.forgot_password_main_form {
  width: 230px;
}

.info_content {
  display:none;
}

.infoIcon {
  float: left;
}

.info_text {
  margin-left: 40px;
}

.info_first {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #7CB1FF;
  margin-bottom: 4px;
}

.info_second {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 15px;
  color: #263238;
}
