.add-payment-type-container {
    width: 330px;
    height: 335px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(124, 177, 255, 0.25);
    border-radius: 4px;
}

.add-payment-type-container-step-2 {
    height: 567px;
}

.add-payment-type-container .RSPBprogressBar {
    top: 32px !important;
}

.add-payment-type-title {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.close-btn {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    border: transparent;
    background: transparent;
    color: #67DB88;
}
