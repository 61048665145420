.review-item {
  width: 670px;
  height: 242px;

  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(124, 177, 255, 0.25);
  border-radius: 4px;

  padding-top: 20px;
  padding-left: 20px;
}

.name-section {
  position: relative;
  margin-left: 90px;

  width: 181px;
  height: 20px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #262326;
}

.time-section {
  margin-top: -5px;
  margin-left: 90px;
  width: 100px;
  height: 15px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #263238;
}

.dot-icon {
  margin-top: -55px;
  margin-left: 158px;
}

.date-section {
  margin-top: -44px;
  margin-left: 165px;

  width: 61px;
  height: 15px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #263238;
}

.review-section {
  height: 100px;
  width: 550px;
  border: #262326;

  margin-top: 40px;
  margin-left: 10px;
  margin-right: 10px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #263238;
}
