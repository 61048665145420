.confirmation-modal .modal-content {
    width: 330px;
    background: #FFFFFF;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
    font-family: Montserrat;
    padding-bottom: 24px;
}

.confirmation-modal .confirmation-modal-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-top: 24px;
    margin-left: 27px;
}

.confirmation-danger .confirmation-modal-title {
    color: #FF7E7E;
}

.confirmation-primary .confirmation-modal-title {
    color: #7CB1FF;
}

.confirmation-info .confirmation-modal-title {
    color: #494949;
}

.confirmation-modal-keep {
    background: #FFFFFF;
    border: 1px solid #67DB88;
    box-sizing: border-box;
    border-radius: 2px;
}

.confirmation-modal-keep {
    background: #FFFFFF;
    border: 1px solid #67DB88;
    box-sizing: border-box;
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #67DB88;
    width: 100px;
    height: 40px;
}

.confirmation-modal-submit {
    box-sizing: border-box;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    border-radius: 2px;
    width: 120px;
    height: 40px;
    border: unset;
}

.confirmation-danger .confirmation-modal-submit {
    background: #FF7E7E;
}

.confirmation-primary .confirmation-modal-submit {
    background: #7CB1FF;
}

.confirmation-info .confirmation-modal-submit {
    background: #67DB88;
}

.confirmation-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
}