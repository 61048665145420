/* input[type="radio"] {
  display: none;
}
 */
.filled-star {
  cursor: pointer;
}

.star-disabled .filled-star {
  cursor: initial;
}