.sidebar {
  background-color: #67db88;
  /* width: 200px; */
  width: 240px;
  height: 100vh;
  position: fixed;
  justify-content: center;
}

.sidebarlist {
  width: 100%;
  height: auto;
  padding-left: 0px;
}

.sidebar-logo {
  width: 140px;
  height: 120px;
  margin: 50px;
  margin-top: 20px;
}

.sidebar-version {
  height: 5%;
  /* width: 200px; */
  width: 240px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  color: #67db88;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  text-decoration: none;
  position: absolute;
  bottom: 0px;
}