.project-item {
  background: white;
  box-shadow: 0px 4px 12px rgba(124, 177, 255, 0.25);
  border-radius: 4px;
  display: block;
  width: 100%;
  height: 242px;
  padding: 30px 0px;
}

.project_donations-a:hover {
  text-decoration: none;
}

.project-info-container {
  margin-left: 33px;
}

.project-image-container {
  width: 40%;
}

.project-image {
  border-radius: 4px;
  margin-right: 36px;
  text-align: center;
  background-position: center!important;
  background-repeat: no-repeat!important;
  position: relative;
  width: 165px;
  height: 165px;
}

#project-image-layer {
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.layer-ended {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 200, 200, 0.71) 100%);
}

.layer-disabled {
  background: linear-gradient(180deg, rgba(255, 126, 126, 0.58) 0%, rgba(255, 200, 200, 0.71) 100%);
}

.project-status {
  width: 70px;
  height: 20px;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  padding: 4px;
}

.project-disabled {
  color: #FFFFFF;
  background: #FF7E7F;
  border: 1px solid #FFFFFF;
  position: relative;
  top: 50%;
}

.project-disabled:hover {
  background: #FF7E7F!important;
  border: 1px solid #FFFFFF!important;
}

.project-ended {
  color: #FF7E7E;
  background: #FFFFFF;
  border: #FFFFFF;
  position: relative;
  top: 75%;
}

.project-overlay {
  left: unset !important;
  right: unset !important;
}

.project-ended:hover {
  color: #FF7E7E!important;
  background: #FFFFFF!important;
  border: #FFFFFF!important;
}

.project-title-section {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19.5px;
  color: #263238;
}

.project-author-container {
  margin-top: 17px;
  height: 15px;
  font-size: 12px;
  font-style: normal;
  line-height: 14.6px;
  letter-spacing: 0em;
  text-align: left;
}

.project-author-section {
  font-weight: 400;
  color: #90ad98;
}

.project-author-item {
  font-weight: 500;
  color: #263238;
}

.project-overview {
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13.41px;
  color: #90ad98;
}

.amount-rasied-section {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  text-transform: none;
  color: #263238;
  width: 60%;
}

.pitem-coin-icon {
  margin-right: 10px;
  width: 21px;
  height: 20px;
}

.raised-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  width: max-content;
}

.of-amount {
  text-transform: none;
  font-weight: 400;
  color: #263238;
}

.enddate-section {
  margin-top: 10px;
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.end-on-h {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #263238;
  display: flex;
}

.alert-dismissible .close {
  margin-top: -7px;
  color: #7CB1FF;
}

.end_date_txt {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #67DB88;
}

.project_item_bottom {
  display: block;
  margin-left: 33px;
  width: 60%;
  position: relative;
  bottom: 40px;
}


.p_item_first_section {
  width: 60%;
  height: max-content;
}

.end-date-on-h_span {
  margin-right: 8px;
}

@media (max-width: 720px) {
  .project-item {
    padding-top: 18px;
    height: max-content;
    padding-bottom: 18px;
  }

  .project-info-container {
    margin-left: 17px;
  }

  .project-title-section {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    width: 148px;
  }

  .project-author-container {
    margin-top: 10px;
    height: 12px;
    font-size: 10px;
    font-style: normal;
    line-height: 12.19px;
    letter-spacing: 0em;
    text-align: left;
  }

  .project-overview {
    margin-top: 17px;
    margin-bottom: 0px;
    font-size: 9px;
    line-height: 11px;
  }

  .raised-amount {
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
  }

  .of-amount {
    font-weight: 400;
  }

  .project-image-container {
    margin-right: 15px;
    text-align: right;
    height: max-content;
  }

  .project-image {
    width: 90px;
    height: 90px;
    margin-right: unset;
    margin-left: 35px;
  }

  .enddate-section {
    display: block;
    margin-top: 0px;
    width: 25%;
  }

  .end-on-h {
    width: max-content;
  }

  .project_item_bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    position: initial;
    margin-left: 0px;
    width: 90%;
    margin-right: 17px;
  }

  .amount-rasied-section {
    width: 65%;
    margin-left: 17px;
  }

  .ended_date-div {
    position: relative;
    top: 7px;
  }

  .project-overlay {
    top: 18px !important;
    right: 30px !important;
    left: unset !important;
    bottom: unset !important;
  }

  .project-ended {
    top: 65%;
  }
}

.pjt_end_date-tooltip .arrow {
  display: none;
}

.pjt_end_date-tooltip .tooltip-inner {
  padding: 0.25rem 0.5rem;
  text-align: start;
  background-color: #ffffff;
  border: 1px solid #3284ff;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
  border-radius: 4px;
  width: max-content;
  height: max-content;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  padding: 8px;
  color: #7CB1FF;
}

@media (min-width: 370px) {
  .pjt_end_date-tooltip .bs-tooltip-auto[x-placement^=right], .bs-tooltip-right {
    top: -28px!important;
    left: -205px!important;
  }
}

@media (min-width: 1100px) {
  .pjt_end_date-tooltip .bs-tooltip-auto[x-placement^=right], .bs-tooltip-right {
    top: -25px!important;
    left: -10px!important;
  }
}
