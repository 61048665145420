.donate_support_modal .modal-dialog {
    width: 330px;
    height: 518px;
    background: #FFFFFF;
    border-radius: 4px;
    -webkit-transform: translate(0,-50%)!important;
    -o-transform: translate(0,-50%)!important;
    transform: translate(0,-50%)!important;
    top: 50%;
    margin: 0 auto;
}

.donate_support_modal .modal-body {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.supporting_title {
    margin-right: 25px;
    margin-left: 25px;
    width: auto;
    height: 44px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #67DB88;
    position: relative;
    bottom: 30px;
}

.supporting_txt {
    margin-right: 25px;
    margin-left: 25px;
    width: auto;
    height: 45px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #263238;
    margin-top: 20px;
}

.supporting_txt span {
    color: #67DB88;
}

.supporting_to-h5 {
    width: auto;
    height: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #263238;
    text-transform: unset;
    letter-spacing: normal;
    margin-top: 30px;
}

#support_donation_summary-a {
    width: 250px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #7CB1FF;
    box-sizing: border-box;
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #7CB1FF;
    text-transform: unset;
    letter-spacing: normal;
}