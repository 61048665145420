#write_review-btn {
    width: 170px;
    height: 32px;
    background: #7CB1FF;
    border-radius: 2px;
    padding: 9px 0px;
    margin-bottom: 15px;
}
  
#write_review-btn > p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    text-transform: lowercase;
    margin-top: -4px;
}
  
#write_review-btn > p::first-letter {
    text-transform: uppercase;
}

#write-icon {
    float: left;
    width: 11.38px;
    height: 11.05px;
    position: relative;
    left: 20px;
}
