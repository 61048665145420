#account-change-password {
    width: 100%;
    max-width: 330px;
    height: 42px;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(124, 177, 255, 0.25);
    border-radius: 4px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    outline: none;
    border: none;
    color: #263238;
    display: block;
    margin-bottom: 24px;
}

#log-out-account-button {
    width: 100%;
    max-width: 330px;
    height: 42px;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(124, 177, 255, 0.25);
    border-radius: 4px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    outline: none;
    border: none;
    color: #263238;
    display: block;
}

#change-password-icon {
    margin-left: -120px;
    margin-right: 20px;
}

#logout-icon {
    margin-left: -187px;
    margin-right: 20px;
}

@media (max-width: 720px) {
    .org-account-top-bg {
        width: 75px;
    }
    .account-right-side {
        margin-top: 20px;
    }
    #log-out-account-button {
        max-width: 100%;
    }
    #account-change-password {
        max-width: 100%;
    }
}