.replace_file_form::-webkit-file-upload-button {
    visibility: hidden;
}

.replace_file_form::before {
    width: 284px;
    height: 36px;
    background: #FDFDFD;
    border: 1px solid #C5E8CF;
    box-sizing: border-box;
    border-radius: 4px;
}

.uploaded_file {
    width: 284px;
    height: 36px;
    background: #FDFDFD;
    border: 1px solid #C5E8CF;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #3284FF;

}

#fileUploadDiv {
    width: 88px;
    height: 30px;
    border: 1px solid #67DB88;
    background-color: #FFFFFF!important;
    position: relative;
    bottom: 10px;
}

.form-control-file::-webkit-file-upload-button {
    visibility: hidden;
}

.form-control-file::before {
    content: 'Upload';
    background: transparent;
    border: transparent;
    box-sizing: border-box;
    border-radius: 2px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #67DB88;
    padding-left: 20px;
}

#replace_file-button {
    background-image: url('../../../images/close-icon.png');
    background-repeat: no-repeat;
    position: relative;
    bottom: 23px;
    background-color: transparent;
    float: right;
    padding-right: 10px;
}

#replace_file-button:hover {
    border: none;
}

@media (max-width: 720px) {
    #replace_file-button {
        left: 10px;
    }
}