.organization-opening-row-container {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.see-more-btn-container {
    text-align: unset;
    padding-left: 10px;
    padding-right: 10px;
}

@media (max-width: 720px) {
    .see-more-btn-container {
        text-align: center;
        padding-left: unset;
        padding-right: unset;
    }
}