.stats-month-selector {
    background: #FFFFFF !important;
    border: 1px solid #7CB1FF !important;
    border-radius: 2px !important;
    padding: 5px 16px !important;
    margin-right: 10px !important;
    margin-top: 10px !important;
    color: #7CB1FF;
    text-align: center;
    text-transform: capitalize;
}

.stats-month-selector:active, .stats-month-selector:focus, .stats-month-selector:hover, .stats-month-selector.active {
    background: #7CB1FF !important;
    color: #FFFFFF !important;
}

#stats_modal .react-date-picker--disabled {
    background: #EAFFF0 !important;
    border: 1px solid #C5E8CF !important;
    color: #90AD98 !important;
}

#stats_modal .react-date-picker {
    background: #FFFFFF;
    border: 1px solid #7CB1FF;
    color: #263238 !important;
    font-weight: normal;
    font-size: 14px;
}