#contributor-home {
  display: flex;
}

#contributor-home .title-items-container {
  display: flex;
  flex-direction: row;
}

.Home-update-item {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #263238;
  text-transform: unset;
}

.Home-info-item {
  margin-left: 10px;
}

.Home-info-item img {
  vertical-align: unset;
  margin-top: 2px;
  width: 15px;
  height: 15px;
}

.Home-Org-Item {
  position: relative;
  margin-top: 22px;
  /* margin-left: 240px; */
}

.Home-Proj-Item {
  position: relative;
  margin-top: 20px;
  /* margin-left: 240px; */
}

#contributor-home .right-section {
  margin-left: 40px;
}

.home-stats {
  position: relative;
  /* top: -600px;
  left: 1000px; */
}

.home-trending {
  position: relative;
  margin-top: 25px;
  /* top: -580px;
  left: 1000px; */
}

.updates_tooltip {
  transform: translate3d(380px, 130px, 0px)!important;
}

.updates_tooltip .tooltip-inner {
  width: max-content!important;
  max-width: 260px;
  padding: 0.25rem 0.5rem;
  color: #3284ff!important;
  text-align: start;
  background-color: #ffffff!important;
  box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
  border: 1px solid #3284ff!important;
  border-radius: 4px;
}

.updates_tooltip .arrow::before {
  display: none;
}

.updates_tooltip .arrow {
  top: 0px!important;
}

@media (max-width: 720px) {
  .updates_tooltip .tooltip-inner {
    max-width: 184px;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
  }
  .updates_tooltip {
    transform: translate3d(120px, 395px, 0px)!important;
  }
  #contributor-home {
    flex-direction: column-reverse;
  }
  #contributor-home .right-section {
    margin-left: 0px;
  }
  .trending-field {
    height: auto;
  }
  .left-section {
    position: relative;
    top: 100px;
  }
}
