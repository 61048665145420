#reset_password {
  padding: 0;
}

#reset_password,
.reset_password-section {
  height: 900px;
}

.reset_password-inner {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.reset_password_item {
  background-image: url(../../images/reset-password-no-green.png);
  background-repeat: no-repeat;
  height: 900px;
  width: 100%;
}

#reset_password .reset_password-inner .reset_password_item {
  background-size: 100% 100%;
}

@media (max-width: 720px) {
  #reset_password,
  .reset_password-section {
    height: 800px;
  }

  .reset_password_item {
    background-image: url(../../images/reset-password-no-green-mobile.png);
    background-repeat: no-repeat;
    height: 835px;
    width: 100%;
  }
}

#reset_password_nav_content-div {
  padding-top: 45px;
}

@media (max-width: 720px) {
  #reset_password_nav_content-div {
    margin-top: -25px;
  }
}

#nav_second_col-div {
  margin-top: 23px;
}

.contact_us_link {
  font-family: 'Montserrat';
  font-style: normal;
  background-color: transparent;
  border: transparent;
  color: #7CB1FF;
  font-weight: 500;
  font-size: 12px;
  margin-right: 92px;
}

@media (max-width: 720px) {
  .contact_us_link {
      margin-top: 2px;
      margin-right: 30px;
  }
}

.sadaqa-logo {
  width: 138px;
  height: 106px;
}

#nav_logo-div {
  margin-top: -70px;
}

@media (max-width: 720px) {
  #nav_logo-div {
    margin-top: 10px;
  }
}

#reset_password_form-div {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.reset_password_title {
  font-family: "Montserrat";
  font-style: normal;
  text-transform: lowercase;
  float: left;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0em;
  color: #67db88;
}

.reset_password_title::first-letter {
  text-transform: uppercase;
}

.reset_password_div {
  margin-bottom: 0px;
}

.reset_password_input {
  width: 230px;
  height: 36px;
  background: #FDFDFD;
  border: 1px solid #C5E8CF;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 5px 15px;
}

.reset_password_input::placeholder {
  height: 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #72AE87;
  transform: unset;
}

.back_to_signin {
  margin-top: 30px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #7cb1ff;
}

.reset_password_button {
  width: 228px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  background: #67db88;
  border-radius: 2px;
  letter-spacing: 0em;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fdfdfd;
  border: none;
  padding: 0px;
}

.reset_password_button:hover {
  color: #fff;
  background-color: #67db88;
  text-decoration: none;
}

.to_login:hover {
  text-decoration: none;
}

.reset_password_show {
  color: #67db88;
  position: relative;
  left: 200px;
  bottom: 28px;
}

.reset_password_error {
  color: red;
  font-size: 10px;
  margin-top: -12px;
}