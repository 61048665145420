#link {
  width: 100%;
  height: 50px;
  list-style-type: none;
  display: flex;
  color: #ffffff;
  justify-content: left;
  align-items: center;
  font-family: Montserrat;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  padding: 20px;
  margin-left: 0px;
}

#activelink {
  width: 100%;
  height: 50px;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 80px;
  color: #67db88;
  background-color: #fff;
}

#link:hover {
  color: #67db88;
  background: #fff;
  cursor: pointer;
}

#droplink {
  background: #67db88;
  width: 100%;
  height: 50px;
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: row;
  color: #fff;
  align-items: center;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  padding-left: 30px;
}

#active-droplink {
  background: #fff;
  width: 100%;
  height: 50px;
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: row;
  color: #67db88;
  align-items: center;
  text-decoration: none;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 30px;
}

#droplink:hover {
  background: #fff;
  color: #67db88;
  cursor: pointer;
}

#title {
  text-align: left;
  justify-content: left;
  margin-left: 11.5px;
}

#sub-title {
  align-items: center;
  justify-content: center;
}

#sub-icon {
  margin-left: 50px;
}

#icon svg {
  width: 100%;
  height: 100%;
}

#icon svg path {
  width: 100%;
  height: 100%;
}

.ic-menu {
  background-position: center !important;
  background-repeat: no-repeat !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  vertical-align: unset;
}

.ic-home {
  background: url("../../../images/menu_icons/ic_menu_home.png");
  width: 20.5px;
  height: 21.51px;
}

.ic-home-visited {
  background: url("../../../images/menu_icons/ic_menu_home_active.png");
  width: 20.5px;
  height: 21.51px;
}

.ic-org {
  background: url("../../../images/menu_icons/ic_menu_org.png");
  width: 18.2px;
  height: 19.2px;
}

.ic-org-visited {
  background: url("../../../images/menu_icons/ic_menu_org_active.png");
  width: 18.2px;
  height: 19.2px;
}

.ic-projects {
  background: url("../../../images/menu_icons/ic_menu_projects.png");
  width: 19.5px;
  height: 19.5px;
}

.ic-projects-visited {
  background: url("../../../images/menu_icons/ic_menu_projects_active.png");
  width: 19.5px;
  height: 19.5px;
}

.ic-donate {
  background: url("../../../images/menu_icons/ic_menu_donate.png");
  width: 21px;
  height: 21px;
}

.ic-donate-visited {
  background: url("../../../images/menu_icons/ic_menu_donate_active.png");
  width: 21px;
  height: 21px;
}

.ic-account {
  background: url("../../../images/menu_icons/ic_menu_account.png");
  width: 15.84px;
  height: 19.87px;
}

.ic-account-visited {
  background: url("../../../images/menu_icons/ic_menu_account_active.png");
  width: 15.84px;
  height: 19.87px;
}

.ic-stats {
  background: url("../../../images/menu_icons/ic_menu_stats.png");
  width: 19px;
  height: 19px;
}

.ic-stats-visited {
  background: url("../../../images/menu_icons/ic_menu_stats_active.png");
  width: 19px;
  height: 19px;
}

.ic-contrib {
  background: url("../../../images/menu_icons/ic_menu_contrib.png");
  width: 20px;
  height: 18px;
}

.ic-contrib-visited {
  background: url("../../../images/menu_icons/ic_menu_contrib_active.png");
  width: 20px;
  height: 18px;
}

.ic-updates {
  background: url("../../../images/menu_icons/ic_menu_updates.png");
  width: 16.5px;
  height: 21px;
}

.ic-updates-visited {
  background: url("../../../images/menu_icons/ic_menu_updates_active.png");
  width: 16.5px;
  height: 21px;
}

.ic-reviews {
  background: url("../../../images/menu_icons/ic_menu_review.png");
  width: 20px;
  height: 19px;
}

.ic-reviews-visited {
  background: url("../../../images/menu_icons/ic_menu_review_active.png");
  width: 20px;
  height: 19px;
}

.ic-donations {
  background: url("../../../images/menu_icons/ic_menu_donations.png");
  width: 20px;
  height: 19px;
}

.ic-donations-visited {
  background: url("../../../images/menu_icons/ic_menu_donations_active.png");
  width: 20px;
  height: 19px;
}