#coming_soon_main {
    padding: 0;
}
  
#coming_soon_main, .coming_soon-section {
    height: 1132px;
}
  
.coming_soon-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}
  
.coming_soon-item {
    background-image: url(../../images/sadaqasbg.png);
    background-repeat: no-repeat;
    height: 1132px;
}
  
#coming_soon_main .coming_soon-inner .coming_soon-item {
    background-size: 100% 1132px;
}
  
@media (max-width: 720px) {
    #coming_soon_main, .coming_soon-section {
        height: 1132px;
    }
    .coming_soon-item {
        background-image: url(../../images/sadaqasbg.png);
        background-repeat: no-repeat;
        height: 1132px;
    }
    #coming_soon_main .coming_soon-inner .coming_soon-item {
        background-size: cover;
        background-position: center center;
        text-align: center;
    }
}

#coming_soon_top_content-div {
    padding-top: 50px;
}

@media (max-width: 720px) {
    #coming_soon_top_content-div {
        position: relative;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 25px;
    }
}

#coming_soon_body-div {
    position: relative;
    margin-top: 100px;
}
