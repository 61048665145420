#org_recurring_donation_item {
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
    border-radius: 4px;
}

#org_recurring_donation_item:hover {
    text-decoration: none;
}

.when_recurring_donation {
    height: 22px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #7CB1FF;
    display: flex;
}

.recurring_donation_hour {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #263238;
}

.recurring_donation_date {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #263238;
}

.view_summary-btn {
    width: 100px;
    height: 25px;
    background: #67DB88;
    border-radius: 2px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    border: 1px solid #67DB88;
}

.view_summary-a:hover {
    text-decoration: none;
}

@media (max-width: 720px) {
    #org_recurring_donation_item {
        width: 100%;
        height: 80px;
        margin-top: 15px;
    }
    .when_recurring_donation {
        height: 17px;
        font-size: 14px;
        display: block!important;
        position: relative;
        left: 15px;
        bottom: 15px;
    }
    .recurring_donation_hour {
        font-size: 10px;
        line-height: 12px;
        position: relative;
        left: 105px;
        bottom: 25px;
        width: 50px
    }
    .recurring_donation_date {
        font-size: 10px;
        line-height: 12px;
        position: relative;
        left: 108px;
        bottom: 25px;
    }
    .recurring_donation_duration {
        position: relative;
        top: 5px;
        right: 25px;
    }
}
