.create-new-project-container {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
    border-radius: 4px;
    height: -moz-fit-content;
    height: fit-content;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    display: flex;
}

.create-new-project-top-bg {
    background-image: url("../../../../images/ic_recurring_top_bg.png");
    width: 196px;
    min-width: 196px;
    height: 242px;
    background-repeat: no-repeat;
    position: sticky;
}

.create-new-project-container .inner-container {
    margin-top: 45px;
    width: 100%;
    margin-left: 22px;
}

.create-new-project-container .crn-profile-image {
    margin-top: 14px;
    width: 210px;
    height: 210px;
    cursor: pointer;
    background: url("../../../../images/ic_add_photo.png");
    background-position: center;
    background-repeat: no-repeat;
}

.create-new-project-container .crn-profile-image-1 {
    opacity: 0;
    width: 210px;
    height: 210px;
    cursor: pointer;
}

.create-new-project-container .crn-profile-image-1::before {
    content: "";
}

.crn-image-uploaded {
    margin-top: 14px;
    width: 210px;
    height: 210px;
    border-radius: 4px;
}

.crn-2 {
    margin-top: 27px;
}

.crn-label {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    color: #263238;
}

.create-new-project-container .input_field {
    font-weight: 500;
}

.crn-text-area {
    min-height: 100px !important;
}

.create-new-project-container .radio-input {
    float: left;
    position: unset;
    margin-right: 10px;
    margin-top: 13px;
    background: #FFFFFF;
    box-sizing: border-box;
    cursor: pointer;
}

.create-new-project-container input[type='radio'] {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    outline: none;
    border: 1px solid #67DB88;
}

.create-new-project-container input[type='radio']:before {
    content: '';
    display: block;
    width: 70%;
    height: 70%;
    margin: 17% auto;
    border-radius: 50%;
}

.create-new-project-container input[type="radio"]:checked:before {
    background: #67DB88;
}

.create-new-project-container input[type="radio"]:checked {
    border: 1px solid #67DB88;
}

.crn-radio-label {
    width: fit-content;
    padding-left: 0px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #263238;
}

.crn-end-date {
    width: 154px;
    height: 36px;
    border: 1px solid #C5E8CF;
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: 10px;
    margin-top: -8px;
    cursor: pointer;
}

.create-new-project-container .react-date-picker--disabled {
    background: #EEFFF3 !important;
    cursor: initial;
}

.crn-danger-circle {
    width: 16px;
    height: 16px;
    margin-left: 20px;
}

.crn-danger-circle-hidden {
    display: none;
}

.crn-danger-circle-visible {
    display: block;
}

.crn-ends-at {
    background: #FFFFFF;
    border: 1px solid #7CB1FF;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(124, 177, 255, 0.25);
    border-radius: 4px;
    width: 140px;
    height: 30px;
    color: #7CB1FF;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0em;
    z-index: 1;
    position: absolute;
    top: -25px;
    left: 182px;
}

.crn-project-goal-input-container {
    width: 284px;
}

.crn-project-goal-label {
    width: 40px;
    height: 40px;
    background: #67DB88;
    border-radius: 4px 0px 0px 4px;
    height: 40px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}

.crn-project-goal-input {
    width: 225px !important;
    height: 40px;
    border: 1px solid #C5E8CF;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #263238;
    border-radius: 0px 4px 4px 0px;
    background-color: white;
}

.crn-btns-container {
    float: right;
}

.crn-discard {
    width: 54px;
    height: 22px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #7CB1FF;
    border: unset;
    background-color: transparent;
}

.crn-create {
    width: 134px;
    padding-top: 8px;
    padding-bottom: 8px;
    background: #67DB88;
    border-radius: 2px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    border: unset;
}

.crn-create:disabled {
    background-color: #cccccc;
}

.crn-bottom-container {
    margin-bottom: 40px;
}

@media (max-width: 720px) {
    .create-new-project-container .inner-container {
        margin-left: -30px;
        z-index: 10;
    }
    .create-new-project-top-bg {
        width: 75px;
        min-width: 75px;
        height: 75px;
        background-size: contain;
    }
    .create-new-project-container .crn-profile-image {
        width: 90px;
        height: 90px;
        background-size: cover;
        background: url("../../../../images/ic_add_photo_resp.svg");
    }
    .create-new-project-container .crn-profile-image-1 {
        width: 90px;
        height: 90px;
    }
    .crn-image-uploaded {
        width: 90px;
        height: 90px;
    }
    .crn-project-goal-input-container {
        width: 260px;
    }
    .crn-project-goal-label {
        width: 10px;
    }
    .crn-project-goal-input {
        width: 200px !important;
    }
    .crn-bottom-container {
        margin-top: 20px;
    }
    .crn-discard {
        padding-top: 10px;
    }

    .react-calendar {
        width: 250px;
    }
}

@media (max-width: 320px) {
    .create-new-project-container .inner-container {
        margin-left: -60px;
        z-index: 10;
    }
}