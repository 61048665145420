.tabs {
  margin-right: 0px;
  margin-top: 0px;
  background-image: url("../../images/tabBg.svg");
  background-repeat: no-repeat;
  background-position: right top;
  background-color: #fff;
  height: 140px;
  border-bottom: 1px solid #67DB88;
  overflow: hidden;
}

.tab-title {
  position: relative;
  left: 250px;
  top: 30px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  color: #263238;
}

.tab-list {
  margin-left: 200px;
  margin-top: 54px;
  border-bottom: 3px solid #67db88;
  padding-left: 0;
  padding-bottom: 0px;
  height: 50px;
}

.tab-list-item {
  margin-left: 25px;
  background-color: #fff;
  margin-top: 0px;
  display: inline-block;

  padding: 10px 30px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 279% */

  color: #67db88;

  cursor: pointer;
}

.tab-list-active {
  margin-top: 6px;
  background-color: #eefff3;
  border: 2px solid #67db88;
  border-bottom: #eefff3;
  border-width: 1px 1px 0 1px;
  z-index: 1000;
}
