.container {
    max-width: 350px;
}

.container_opening {
    padding-left: 0px;
    padding-right: 30px;
    margin-bottom: 30px;
}

.organization {
    height: 110px;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px #7cb1ff40;
    display: flex;
    padding: 15px 15px;
    background-image: url('../../../images/Vector (1).png');
    background-repeat: no-repeat;
    background-size: 67px 85px;
}

.organization_opening {
    display: block;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px #7cb1ff40;
    background-image: url('../../../images/Vector (1).png');
    background-repeat: no-repeat;
    background-size: 67px 85px;
}

.image {
    height: 60px;
    width: 60px;
    border-radius: 100px;
}

.organization_body {
    margin-left: 20px;
}

.organization_body_opening {
    margin-left: 0px;
    margin-top: 19px;
    text-align: left;
}

.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    color: #263238;
}

.rating {
    margin-top: 10px;
}

.rating_opening {
    margin-top: 10px;
}

.star {
    height: 15px;
    position: relative;
    bottom: 2px;
}

.star_opening {
    width: 13px;
    position: relative;
    bottom: 2px;
}

.value {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #263238;
}

.tags {
    margin-top: 10px;
}

.tags_opening {
    margin-top: 5px;
}

.tag {
    height: 20px;
    background: #7CB1FF;
    border-radius: 2px;
    margin: 0px 5px 0px 0px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    color: #FFFFFF;
}

.remainingTags {
    width: 27px;
    height: 20px;
    background: #7CB1FF;
    border-radius: 2px;
    margin: 0px 5px 0px 0px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    color: #FFFFFF;
}

@media (max-width: 720px) {
    .container {
        margin-left: 10px;
        padding-right: unset;
        margin-top: 24px;
    }
    .container_opening {
        margin-left: unset;
        text-align: center;
        width: 240px;
    }
    .organization_opening {
        margin-left: auto;
        margin-right: auto;
    }
}