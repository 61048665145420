#final_registration-section {
	padding: 0;
}

#final_registration-section,
.final_registration_div {
    height: 1024px;
}

.final_registration_inner {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.final_registration_item {
    background-image: url(../../../images/main-background.png);
    background-repeat: no-repeat;
    height: 1024px;
    width: 100%;
}

#final_registration-section .final_registration_inner .final_registration_item {
    background-size: 100% 100%;
}

@media (max-width: 720px) {
    #final_registration-section,
    .final_registration_div {
        height: 915px;
    }

    .final_registration_item {
        background-image: url(../../../images/mobile-main-background.png);
        background-repeat: no-repeat;
        height: 915px;
        width: 100%;
    }
}


#final_registration_nav-div {
    padding-top: 45px;
}
  
@media (max-width: 720px) {
    #final_registration_nav-div {
        margin-top: -25px;
    }
}

#nav_second_col-div {
    margin-top: 23px;
}
  
.contact_us_link {
    font-family: 'Montserrat';
    font-style: normal;
    background-color: transparent;
    border: transparent;
    color: #7CB1FF;
    font-weight: 500;
    font-size: 12px;
    margin-right: 92px;
}
  
@media (max-width: 720px) {
    .contact_us_link {
        margin-top: 2px;
        margin-right: 30px;
    }
}
  
.sadaqa-logo {
    width: 138px;
    height: 106px;
}
  
#nav_logo-div {
    margin-top: -70px;
}
  
@media (max-width: 720px) {
    #nav_logo-div {
        margin-top: 10px;
    }
}

#main-div {
    margin-top: 90px;
}

@media (max-width: 720px) {
    #main-div {
        margin-top: 50px;
    }
}

.subtitle {
    height: 22px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: #67DB88;
    margin-top: 115px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 720px) {
    .subtitle {
        margin-top: 50px;
    }
}

.subtitle1 {
    width: 335px;
    height: 66px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 155%;
    color: #494949;
    position: relative;
    top: 10px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 720px) {
    .subtitle1 {
        top: 20px;
    }
}

.subtitle2 {
    position: relative;
    width: 235px;
    height: 15px;
    top: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #263238;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 720px) {
    .subtitle2 {
        top: 35px;
    }
}

#back_to_login-btn {
    position: relative;
    width: 228px;
    height: 40px;
    top: 65px;
    background: #67DB88;
    border-radius: 2px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.06em;
    color: #FDFDFD;
    padding: 0px;
    text-transform: unset;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.to_verification:hover,
.to_login:hover {
    text-decoration: none;
}

@media (max-width: 720px) {
    #back_to_login-btn {
        top: 60px;
    }
}

.resend_email {
    position: relative;
    width: 122px;
    height: 20px;
    top: 95px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.06em;
    color: #7CB1FF;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 720px) {
    .resend_email {
        top: 85px;
    }
}
