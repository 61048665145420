.title-of-section {
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0em;
    color: #263238;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
}

@media (max-width: 720px) {
    .title-of-section {
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
    }
}