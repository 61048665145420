.org-account-container {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(124, 177, 255, 0.25);
    border-radius: 4px;
    height: 100%;
    font-family: Montserrat;
    color: #263238;
    /* font-size: 16px;
    line-height: 20px; */
    font-style: normal;
    font-weight: normal;
}

.org-account-top-bg {
    top: 0;
    left: 15px;
    position: absolute;
}

.org-edit-item {
    float: right;
    margin-top: 18px;
    margin-right: 23px;
    color: #7CB1FF;
    cursor: pointer;
    font-size: 12px;
    line-height: 15px;
    background-color: transparent;
    border: none;
    outline: none;
}

.org-account-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.org-account-star {
    width: 14px;
    height: 13.8px;
}

.org-account-rate {
    margin-top: 14px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 12px;
}

.org-account-tag-container {
    display: flex;
    flex-wrap: wrap;
}

.org-account-tag {
    background: #7CB1FF;
    border-radius: 2px;
    padding: 5px 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
}

.org-account-contact-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #90AD98;
}

.org-account-subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.org-account-desc {
    font-size: 16px;
    line-height: 21.12px;
    margin-right: 34px;
    margin-left: 44px;
    margin-top: 50px;
    margin-bottom: 40px;
}

.account-resp {
    display: none;
}

.account-not-resp {
    display: block;
}

@media (max-width: 720px) {
    .org-account-container {
        font-size: 14px;
        line-height: 17px;
    }
    .edit-account-wrapper .edit-account {
        padding-left: 40px;
    }
    .padding-responsive {
        padding-left: 44px;
        padding-right: 44px;
    }
    .account-resp {
        display: block;
        margin-left: 26px;
        margin-top: 15px;
    }
    .account-not-resp {
        display: none;
    }
    .org-account-rate {
        margin-top: 5px;
    }
    .org-account-tag-container {
        margin-top: 18px !important;
    }
    .org-account-desc {
        padding: 22px 33px;
        margin: 0px
    }
    .org-edit-item {
        font-size: 12px;
        line-height: 15px;
    }
    .org-account-title {
        font-size: 14px;
        line-height: 17px;
    }
    .org-account-tag {
        font-size: 9px;
        line-height: 11px;
    }
    .org-account-contact-title {
        font-size: 14px;
        line-height: 17px;
    }
    .contributor-location-section, .contributor-phone-section, .contributor-email-section {
        font-size: 14px;
        line-height: 17px;
    }
    #location-icon {
        height: 16px;
        width: 16px;
    }
    #call-icon {
        height: 15px;
        width: 15px;
    }
    #message-icon {
        height: 15px;
        width: 16px;
    }
    .org-account-subtitle {
        font-size: 14px;
        line-height: 17px;
    }
    .org-account-desc {
        font-size: 14px;
        line-height: 17px;
    }
}

@media (max-width: 320px) {
    .edit-account-wrapper .edit-account {
        padding-left: 10px;
    }
    .input_field {
        width: 250px;
    }
    .about_organization {
        width: 250px;
    }
    .css-4je539-control {
        width: 250px;
    }
    .react-tel-input .form-control {
        width: 250px;
    }
    .uploaded_file {
        width: 250px;
    }

    #replace_file-button {
        left: -22px;
    }
}