#organizations_donation_summary-div {
    width: 1120px;
    height: 490px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
    border-radius: 4px;
    margin-left: 10px;
    padding-left: 65px;
    padding-top: 50px;
}   

.summary_span {
    display: flex; 
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 7px;
}

.donation_summary_lbl {
    height: 15px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #263238;
}

.donation_summary_txt {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    color: #263238;
}

.donation_summary_title {
    height: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #7CB1FF;
    margin-top: 9px;
    margin-bottom: 15px;
}

#donated_to-div {
    margin-top: 45px;
}

#details_info-hr {
    margin-top: 37px;
}

.amount_lbl {
    height: 17px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #263238;
}

.amount_txt {
    height: 22px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: right;
    color: #92E3A9;
}

.donation_summary_hr {
    width: 100%;
    height: 0px;
    background: #92E3A9;
    position: relative;
}

.detail_txt {
    font-style: normal;
    font-weight: 500;
}

.donation_summary_col_one {
    width: 100%;
    padding-right: 65px;
    padding-left: 0px;
} 

.donation_summary_col_one:after {
    content: '';
    height: 395px;
    width: 100%;
    border-right: 1px solid #92E3A9;
    position: absolute;
    top: 0px;
}

.donation_summary_col_two {
    width: 100%;
    padding-right: 0px;
    padding-left: 65px;
}

.donation_summary_col_two:after {
    width: 100%;
}

.summary_address { 
    padding-left: 46px!important;
    width: 100%;
}

.humane_society_txt {
    height: 17px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #263238;
    margin-bottom: 10px;
}

.custom_hr {
    width: 100%;
    color: #DDF3E3;
    margin-top: 7px;
    margin-bottom: 0px;
}

.custom_hr_child {
    margin-top: 15px;
}

@media (max-width: 720px) {
    .orgs_donation_summary-row {
        display: block;
        width: 100%;
    }
    #organizations_donation_summary-div {
        padding-left: 40px;
        padding-top: 10px;
        width: 100%;
        height: 750px;
        margin-bottom: 45px;
    }
    .donation_summary_col_one {
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
        max-width: 100%;
        border-bottom: 1px solid #92E3A9;
    }
    .donation_summary_col_one:after {
        border-right: none;
    }
    .donation_summary_col_two {
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
        max-width: 100%;
        margin-top: 15px;
    }
    #donated_to-div {
        margin-top: 20px;
    }
    .donation_summary_hr {
        padding: 0px;
        margin: 0px;
        bottom: 15px;
    }
    .donation_summary_title {
        margin-top: 0px;
    }
    #contact_details-div {
        margin-bottom: 15px;
    }
    #details_info-hr {
        margin-top: 45px;
    }
    .custom_hr_child {
        margin-top: 29px!important;
    }
    #amount-div {
        position: relative;
        bottom: 10px;
    }
    .created_by_span {
        margin-bottom: 30px;
    }
}

.created_by_span {
    display: flex;
    margin-top: 10px;
}

.created_by_lbl {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #90AD98;
    margin-right: 5px;
}

.created_by_txt {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #263238;
}
