.register_organization_step_one {
    position: relative;
    top: 115px;
}

.input_field {
    width: 284px;
    height: 36px;
    background: #FDFDFD;
    border: 1px solid #C5E8CF;
    box-sizing: border-box;
    border-radius: 4px;
}

.org_signup_error_step_1 {
    color: #ff0000;
    font-size: 10px;
    margin-top: 5px;
}

.org_signup_valid_error_step_1 {
    color: red;
    font-size: 10px;
    position: relative;
    bottom: 15px;
}

.reg_org_next_button {
    position: relative;
    width: 100px;
    height: 35px;
    background: #67DB88;
    color: #FFFFFF;
    border-radius: 4px;
    text-transform: unset;
    padding: 0px;
    float: right;
    left: 80px;
    top: 115px;
}
  
.reg_org_next_button:hover {
    background: #67DB88;
}

@media (max-width: 720px) {
    .reg_org_next_button {
        bottom: 40px;
        left: 0px;
        top: 15px;
    }
}
