.replace_image_form {
    vertical-align: middle;
}

.uploaded_image {
    width: 60px;
    height: 60px;
    border: 1px solid #67DB88;
    box-sizing: border-box;
    border-radius: 30px;
    float:left;
}

#profile_image-div {
    width: 60px;
    height: 60px;
    border: 1px solid #67DB88;
    box-sizing: border-box;
    border-radius: 30px;
    background: url('../../../images/plus.png') no-repeat center;
}

.profile_image_input {
    opacity: 0;
    height: 100%;
    cursor: pointer;
}

#replace_image-div {
    margin: 18px 10px;
    display: inline-block;
}

#replace-button {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #7CB1FF;
    background: transparent;
    border: transparent;
    vertical-align: middle;
    text-transform: unset;
    position: relative;
    bottom: 12px;
    right: 12px;
}

#replace_logo-img {
    height: 11px;
    width: 13px;
    float: left;
    margin-top: 5px;
}
