.donate_share-grp {
    width: 290px;
}

#pi_donate-btn {
    width: 140px;
    height: 40px;
    background: #67DB88;
    border-radius: 2px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FDFDFD;
}

#pi_share-btn {
    width: 80px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #7CB1FF;
    box-sizing: border-box;
    border-radius: 2px;
}

#pi_share-btn > p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #7CB1FF;
    letter-spacing: 0em;
    text-transform: lowercase;
    margin-top: -3px;
}

#pi_share-btn > p::first-letter {
    text-transform: uppercase;
}


.pjt_info_end_date-tooltip .tooltip-inner {
    padding: 0.25rem 0.5rem;
    text-align: start;
    background-color: #ffffff;
    border: 1px solid #3284ff;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
    border-radius: 4px;
    width: max-content;
    height: max-content;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    padding: 8px;
    color: #7CB1FF;
}
  
.pjt_info_end_date-tooltip .arrow {
    display: none;
}
  
@media (min-width: 370px) {
    .pjt_info_end_date-tooltip .bs-tooltip-auto[x-placement^=right], .bs-tooltip-right {
        top: -28px!important;
        left: -10px!important;
    }
}
  
@media (min-width: 1100px) {
    .pjt_info_end_date-tooltip .bs-tooltip-auto[x-placement^=right], .bs-tooltip-right {
        top: -25px!important;
        left: -10px!important;
    }
}
  
@media (max-width: 720px) {
    #pi_share-btn {
        width: 80px;
    }
    #pi_share-btn > p {
        font-size: 12px;
    }
}