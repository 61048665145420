.indexedStep {
    color: white;
    width: 16px;
    height: 16px;
    font-size: 0px;
    background-color: #D9E8FF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.indexedStep.accomplished {
    background-color: #7CB1FF;
}
