#contact_modal .modal-content {
    width: 330px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
    border-radius: 4px;
    margin-top: 10rem!important;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.contact_info {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
}

.contact_name_lbl {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 180%;
    color: #263238;
    margin-bottom: 5px;
}

.contact_name_txt {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #263238;
}

.contact_info_lbl {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 180%;
    color: #263238;
    margin-top: 15px;
}

.contact_number_txt {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #263238;
    margin-top: 10px;
    margin-bottom: 10px;
}

.contact_email_txt {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #263238;
}

.your_msg_lbl {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 180%;
    color: #263238;
    margin-top: 30px;
    margin-bottom: 10px;
}

.your_msg_txt {
    width: 100%;
    height: 148px!important;
    background: #F1FBF0;
    border: 1px solid #C5E8CF;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #263238;
    text-align: justify;
    overflow: scroll;
    padding: 0.5rem 0.5rem;
}

.your_msg_txt::-webkit-scrollbar {
    display:none;
}

.send_msg-btn {
    height: 40px;
    background: #67DB88;
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    text-transform: unset;
}

.discard_msg-btn {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.06em;
    color: #7CB1FF;
    text-transform: unset;
    background-color: transparent!important;
    border: transparent!important;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.discard_msg-btn:hover {
    color: #7CB1FF;
}

.contact_success {
    text-align: center;
}

#contact-vector {
    width: 183px;
    height: 183px;
}

.msg_sent {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: #67DB88;
    margin-top: 20px;
    margin-bottom: 20px;
}

.contacted_lbl {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #494949;
    margin-bottom: 5px;
}

.contacted_txt {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #494949;
}

.ok-btn {
    width: 100px;
    height: 40px;
    background: #67DB88;
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    margin-top: 40px;
    margin-bottom: 20px;
}
