#project-images-info {
  margin-top: -80px;
  margin-left: 40px;
}

#project-images-field {
  width: 670px;
  height: 459px;

  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(124, 177, 255, 0.25);
  border-radius: 4px;

  margin-left: 0px;
}
