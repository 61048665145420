.change-password-container {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(124, 177, 255, 0.25);
    border-radius: 4px;
    height: 100%;
    width: 100%;
    font-family: Montserrat;
    color: #263238;
    font-size: 16px;
    line-height: 20px;
    font-style: normal;
    font-weight: normal;
}

.change-password-top-bg {
    position: absolute;
}

@media (max-width: 720px) {
    .change-password-top-bg {
        width: 75px;
    }
}