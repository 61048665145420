.specific_project_container {  
    padding: 0px;
    position: relative;
    bottom: 20px;
}

.specific_project_amount {
    display: flex;
    position: relative;
    top: 30px;
    left: 18px;
}

.progress {
    margin-left: auto!important;
    margin-right: auto;
    background-color: #E7F1EA;
    position: relative;
    top: 30px;
}

.progress-bar {
    background-color: #7CB1FF;
}

.project_link {
    position: relative;
    top: 25px;
}

.link_icon {
    width: 18px;
    height: 19.46px;
}

.link_txt {
    width: 122px;
    height: 15px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #3284FF;
    position: relative;
    top: 1px;
    left: 10px;
}

.project_date_status { 
    display: flex; 
    justify-content: space-between;
    position: relative;
    top: 35px;
    padding: 19px;
}

.created_project_date {
    display: flex; 
    justify-content: space-between;
    padding: 19px;
    position: relative;
    bottom: 6px;
}

.project_created_on,
.specific_project_end_date {
    height: 16px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: right;
    color: #263238;
    text-transform: capitalize;
}

.created_on_date,
.specific_project_deadline {
    height: 16px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: right;
    color: #263238;
    text-transform: capitalize;
}

.opened_project {
    color: #67DB88!important;
}

.download_specific_donation {
    padding-left: 20px;
}

.specific_project_card {
    width: 360px;
    height: 620px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
    border-radius: 4px;
    background-image: url('../../images/Vector (1).png');
    background-repeat: no-repeat;
    background-size: 155px 212px;
    position: relative;
    right: 45px;
    bottom: 5px;
}

.specific_org_donation {
    width: 630px;
    height: 110px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
    border-radius: 4px;
    display: flex;
    padding: 15px 40px;
    margin-top: 15px;
    cursor: pointer;
}

.specific_org_donation_summary-a:hover {
    text-decoration: none;
}
