.stat-section {
  background: url("../../../images/statBg.svg");
  background-repeat: no-repeat;
  background-position: top left;

  width: 410px;
  height: 124px;

  background-color: #ffff;
  box-shadow: 0px 4px 12px rgba(124, 177, 255, 0.25);
  border-radius: 4px;
}

.stat-icon {
  position: absolute;
  top: 20px;
  left: 20px;
}

.stat-projects {
  position: absolute;
  left: 120px;
  top: 28px;

  width: 60px;
  height: 15px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #263238;
}

.stat-organizations {
  position: absolute;
  left: 250px;
  top: 28px;

  width: 60px;
  height: 15px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #263238;
}

.stat-total-amount {
  position: absolute;
  left: 120px;
  top: 78px;

  width: 300px;
  height: 15px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #263238;
}

.projects-info {
  position: relative;
  top: 23px;
  left: 207px;
}

.organizations-info {
  position: relative;
  top: 2px;
  left: 392px;
}

.projects-input {
  position: absolute;
  top: 46px;
  left: 120px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #67db88;
}

.organizations-input {
  position: absolute;
  top: 46px;
  left: 250px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #67db88;
}

.total-amount-input {
  position: absolute;
  top: 97px;
  left: 120px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #67db88;
}

.projects_tooltip {
  transform: translate3d(1180px, 150px, 0px)!important;
}

.projects_tooltip .tooltip-inner {
  width: max-content;
  padding: 0.25rem 0.5rem;
  color: #3284ff;
  text-align: start;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
  border: 1px solid #3284ff;
  border-radius: 4px;
}

.projects_tooltip .arrow::before {
  display: none;
}

.projects_tooltip .arrow {
  top: 1px!important;
}

.organizations_tooltip {
  transform: translate3d(1170px, 150px, 0px)!important;
}

.organizations_tooltip .tooltip-inner {
  width: max-content;
  padding: 0.25rem 0.5rem;
  color: #3284ff!important;
  text-align: start;
  background-color: #ffffff!important;
  box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
  border: 1px solid #3284ff!important;
  border-radius: 4px;
}

.organizations_tooltip .arrow::before {
  display: none;
}

.organizations_tooltip .arrow {
  top: 0px!important;
}

@media (max-width: 720px) {
  .projects_tooltip .tooltip-inner,
  .organizations_tooltip .tooltip-inner {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
  }
  .projects_tooltip .tooltip-inner {
    max-width: 122px;
  }
  .organizations_tooltip .tooltip-inner {
    max-width: 144px;
  }
  .projects_tooltip {
    transform: translate3d(225px, 165px, 0px)!important;
  }
  .organizations_tooltip {
    transform: translate3d(255px, 165px, 0px)!important;
  }
}
