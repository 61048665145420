#organization_tab-div {
  padding: 0px;
  margin-top: 15px;
}

.donations_date {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #263238;
}

.org_donation {
  width: 630px;
  height: 110px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
  border-radius: 4px;
  display: flex;
  padding: 15px 40px;
  margin-top: 15px;
  cursor: pointer;
}

.org_name {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #263238;
  position: relative;
  top: 29px;
  left: 25px;
}

.donation_value {
  width: 52px;
  height: 30px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #263238;
  padding: 25px;
}

.money_logo {
  padding-left: 50px;
}

.org_details {
  display: flex;
}

.org_donation_details {
  display: flex;
  position: relative;
  left: 150px;
}

.org_card {
  /* width: 360px;
    height: 690px; */
  background: #ffffff;
  box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
  border-radius: 4px;
  background-image: url('../../images/Vector (1).png');
  background-repeat: no-repeat;
  background-size: 155px 212px;
  position: relative;
  /* right: 30px; */
}

#org_logo-div {
  text-align: center;
}

.org_card_logo {
  width: 210px;
  height: 210px;
  justify-content: center !important;
  margin-left: auto;
  margin-right: auto;
}

.org_card_title {
  position: relative;
  top: 30px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #263238;
}

.org_card_rate {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.star_logo {
  padding-right: 10px;
}

.rate_value {
  position: relative;
  top: 2px;
}

.org_contact {
  margin-top: 35px;
  padding-left: 20px;
}

.contact_title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #90ad98;
  text-transform: uppercase;
}

.location_org_logo,
.phone_org_logo,
.email_org_logo {
  width: 16px;
  height: 16px;
}

.location_txt,
.phone_txt,
.email_txt {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #263238;
  position: relative;
  top: 1px;
  left: 15px;
}

.org_amount {
  display: flex;
  margin-top: 30px;
  padding-left: 20px;
}

.amount_logo {
  margin-right: 10px;
  display: block;
}

.total_amount_title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #9bb1a1;
}

.amount_total {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #263238;
}

.org_amount p {
  margin: 10px;
}

.amount_content {
  position: relative;
  top: 14px;
}

.download_donation_txt {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #263238;
}

.download_donation {
  margin-top: 30px;
  padding-left: 20px;
}

#download-btn {
  width: 160px;
  height: 32px;
  color: #ffffff;
  background: #67db88;
  padding-top: 8px;
}

.download_logo {
  float: left;
}

#download-btn > p {
  width: 106px;
  height: 22px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  margin: 0px 8px;
  letter-spacing: 0em;
  text-transform: lowercase;
  margin-top: -3px;
  position: relative;
  left: 5px;
}

#download-btn > p::first-letter {
  text-transform: uppercase;
}
